import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../Form.css";
import FormLine from "./FormLine";
import Header from "./Header";

import axios from "../../axios";
import { formatNumber } from "../../utils";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [societe, setSociete] = useState("");
  const [fonction, setFonction] = useState("");
  const [projectType, setProjectType] = useState(0);
  const [privateCheckbox, setPrivateCheckbox] = useState(false);

  //Options state
  const [finalOptions, setFinalOptions] = useState([]);
  const [finalValues, setFinalValues] = useState([]);
  const [finalNames, setFinalNames] = useState([]);

  const [basePrice, setBasePrice] = useState(0);
  const [cloudPrice, setCloudPrice] = useState(0);

  const [commercialMail, setCommercialMail] = useState("");
  const [protectDataMessage, setProtectDataMessage] = useState("");
  const [mailHypothesis, setMailHypothesis] = useState("");
  const [mailLegal, setMailLegal] = useState("");

  const handleFinalValues = (idx, price, choiceName, optName) => {
    if (
      optName === "Emplacement" ||
      optName === "Infrastructure, Opérations 24/7, 1 an d’engagement"
    ) {
      const newFinalNames = [...finalNames];
      newFinalNames[idx] = choiceName;
      setFinalNames(newFinalNames);
      const newFinalValues = [...finalValues];
      let valueIdx = getOptionNameIdx("Emplacement");

      let locaIdx = getLocaChoosedIdx(newFinalNames);
      let infraIdx = getInfraChoosedIdx(newFinalNames);

      let publicIdx = getOptionNameIdx("Infrastructure Cloud public");
      let privateIdx = getOptionNameIdx("Infrastructure Cloud privé");
      let premiseIdx = getOptionNameIdx("Infrastructure On premise");

      if (locaIdx === 0 && infraIdx === 0) {
        newFinalValues[valueIdx] = finalOptions[publicIdx].options[0].price;
      } else if (locaIdx === 1 && infraIdx === 0) {
        newFinalValues[valueIdx] = finalOptions[privateIdx].options[0].price;
      } else if (locaIdx === 2 && infraIdx === 0) {
        newFinalValues[valueIdx] = finalOptions[premiseIdx].options[0].price;
      } else if (locaIdx === 0 && infraIdx === 1) {
        newFinalValues[valueIdx] = finalOptions[publicIdx].options[1].price;
      } else if (locaIdx === 1 && infraIdx === 1) {
        newFinalValues[valueIdx] = finalOptions[privateIdx].options[1].price;
      } else if (locaIdx === 2 && infraIdx === 1) {
        newFinalValues[valueIdx] = finalOptions[premiseIdx].options[1].price;
      } else if (locaIdx === 0 && infraIdx === 2) {
        newFinalValues[valueIdx] = finalOptions[publicIdx].options[2].price;
      } else if (locaIdx === 1 && infraIdx === 2) {
        newFinalValues[valueIdx] = finalOptions[privateIdx].options[2].price;
      } else if (locaIdx === 2 && infraIdx === 2) {
        newFinalValues[valueIdx] = finalOptions[premiseIdx].options[2].price;
      }
      setFinalValues(newFinalValues);
    } else {
      const newFinalValues = [...finalValues];
      const newFinalNames = [...finalNames];
      newFinalValues[idx] = price;
      newFinalNames[idx] = choiceName;
      setFinalValues(newFinalValues);
      setFinalNames(newFinalNames);
    }
  };

  const getOptionNameIdx = (name) => {
    let idx = 0;
    finalOptions?.forEach((finalOption, i) => {
      if (finalOption.optionName === name) {
        idx = i;
      }
    });
    return idx;
  };

  const getLocaChoosedIdx = (newFinalNames) => {
    let idx = 0;
    finalOptions?.forEach((finalOption, i) => {
      if (finalOption.optionName === "Emplacement") {
        finalOption.options.forEach((option, j) => {
          if (option.name === newFinalNames[i]) {
            idx = j;
          }
        });
      }
    });
    return idx;
  };

  const getInfraChoosedIdx = (newFinalNames) => {
    let idx = 0;
    finalOptions?.forEach((finalOption, i) => {
      if (
        finalOption.optionName ===
        "Infrastructure, Opérations 24/7, 1 an d’engagement"
      ) {
        finalOption.options.forEach((option, j) => {
          if (option.name === newFinalNames[i]) {
            idx = j;
          }
        });
      }
    });

    return idx;
  };

  const submitForm = async (e) => {
    if (!name) {
      alert("Veuillez saisir un nom");
      return false;
    }
    if (!email) {
      alert("Veuillez saisir un email");
      return false;
    } else if (
      !email.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
    ) {
      alert("Veuillez renseigner une adresse mail correcte.");
      return false;
    }

    if (!tel) {
      alert("Veuillez saisir un numéro de téléphone");
      return false;
    } else if (!tel.toString().match(/0[0-9]{9}/)) {
      alert("Veuillez renseigner un téléphone au format : 0x xx xx xx xx");
      return false;
    }
    if (!societe) {
      alert("Nom de société non renséigné");
      return false;
    }
    if (!fonction) {
      alert("Fonction non renseignée");
      return false;
    }

    if (!privateCheckbox) {
      e.preventDefault();
      alert("Veuillez accepter la protection des données personnelles");
    } else {
      let optionsChoosed = [];
      Array.from(Array(finalNames.length)).forEach((value, i) => {
        if (
          finalOptions[i].optionName !== "Infrastructure Cloud public" &&
          finalOptions[i].optionName !== "Infrastructure Cloud privé" &&
          finalOptions[i].optionName !== "Infrastructure On premise"
        )
          if (projectType === 0) {
            if (finalOptions[i].type === 0) {
              optionsChoosed[i] = {
                name: finalOptions[i].optionName,
                option: finalNames[i],
              };
            }
          } else {
            optionsChoosed[i] = {
              name: finalOptions[i].optionName,
              option: finalNames[i],
            };
          }
      });

      const basicPrice = projectType === 0 ? basePrice : basePrice + cloudPrice;
      const finalPrice = formatNumber(
        (sumTotalAmount() + basicPrice).toFixed(2)
      );
      console.log(optionsChoosed);
      alert(`L’estimation de votre projet a été envoyé à votre adresse mail.`);
      await axios
        .post("/", {
          name,
          email,
          tel,
          societe,
          fonction,
          finalPrice,
          optionsChoosed,
          commercialMail,
          mailHypothesis,
          mailLegal,
        })
        .then((response) => {
          setName("");
          setEmail("");
          setTel("");
          setSociete("");
          setFonction("");
        })
        .catch((error) => {
          console.log("ERREUR POST CLIENT");
        });
      window.location.reload(true);
    }
  };

  const getOptionIdxByName = (name, finalOptionId) => {
    let index = 0;
    finalOptions.forEach((finalOption, i) => {
      if (i === finalOptionId) {
        finalOption.options.forEach((option, j) => {
          if (option.name === name) {
            index = j;
          }
        });
      }
    });
    return index;
  };

  const handleProjectType = (projectType) => {
    if (projectType === 0) {
      setProjectType(0);
      const locaIdx = getOptionNameIdx("Emplacement");
      const arrayValues = [];
      finalOptions.forEach((finalOption, i) => {
        if (
          finalOption.optionName !== "Emplacement" &&
          finalOption.optionName !==
            "Infrastructure, Opérations 24/7, 1 an d’engagement" &&
          finalOption.optionName !== "Infrastructure Cloud public" &&
          finalOption.optionName !== "Infrastructure Cloud privé" &&
          finalOption.optionName !== "Infrastructure On premise" &&
          finalOption.type !== 1
        ) {
          arrayValues.push(
            finalOption.options[getOptionIdxByName(finalNames[i], i)].price
          );
        } else {
          arrayValues.push(0);
        }
      });
      const newFinalValues = [...arrayValues];
      newFinalValues[locaIdx] = 0;
      setFinalValues(newFinalValues);
    } else if (projectType === 1) {
      setProjectType(1);

      const locaIdx1 = getOptionNameIdx("Emplacement");
      const infraIdx1 = getOptionNameIdx(
        "Infrastructure, Opérations 24/7, 1 an d’engagement"
      );
      let newFinalNames = [...finalNames];
      newFinalNames[locaIdx1] = finalOptions[locaIdx1].options[0].name;
      newFinalNames[infraIdx1] = finalOptions[infraIdx1].options[0].name;
      setFinalNames(newFinalNames);

      const arrayValues = [];
      finalOptions.forEach((finalOption, i) => {
        if (
          finalOption.optionName !== "Emplacement" &&
          finalOption.optionName !==
            "Infrastructure, Opérations 24/7, 1 an d’engagement" &&
          finalOption.optionName !== "Infrastructure Cloud public" &&
          finalOption.optionName !== "Infrastructure Cloud privé" &&
          finalOption.optionName !== "Infrastructure On premise"
        ) {
          arrayValues.push(
            finalOption.options[getOptionIdxByName(finalNames[i], i)].price
          );
        } else {
          arrayValues.push(0);
        }
      });
      const newFinalValues = [...arrayValues];

      let valueIdx = getOptionNameIdx("Emplacement");

      let locaIdx = getLocaChoosedIdx(newFinalNames);
      let infraIdx = getInfraChoosedIdx(newFinalNames);

      let publicIdx = getOptionNameIdx("Infrastructure Cloud public");
      let privateIdx = getOptionNameIdx("Infrastructure Cloud privé");
      let premiseIdx = getOptionNameIdx("Infrastructure On premise");

      if (locaIdx === 0 && infraIdx === 0) {
        newFinalValues[valueIdx] = finalOptions[publicIdx].options[0].price;
      } else if (locaIdx === 1 && infraIdx === 0) {
        newFinalValues[valueIdx] = finalOptions[privateIdx].options[0].price;
      } else if (locaIdx === 2 && infraIdx === 0) {
        newFinalValues[valueIdx] = finalOptions[premiseIdx].options[0].price;
      } else if (locaIdx === 0 && infraIdx === 1) {
        newFinalValues[valueIdx] = finalOptions[publicIdx].options[1].price;
      } else if (locaIdx === 1 && infraIdx === 1) {
        newFinalValues[valueIdx] = finalOptions[privateIdx].options[1].price;
      } else if (locaIdx === 2 && infraIdx === 1) {
        newFinalValues[valueIdx] = finalOptions[premiseIdx].options[1].price;
      } else if (locaIdx === 0 && infraIdx === 2) {
        newFinalValues[valueIdx] = finalOptions[publicIdx].options[2].price;
      } else if (locaIdx === 1 && infraIdx === 2) {
        newFinalValues[valueIdx] = finalOptions[privateIdx].options[2].price;
      } else if (locaIdx === 2 && infraIdx === 2) {
        newFinalValues[valueIdx] = finalOptions[premiseIdx].options[2].price;
      }
      setFinalValues(newFinalValues);
    }
  };

  const sumTotalAmount = () => {
    const prices = Object.values(finalValues);
    const total = prices.reduce((base, acc) => base + acc, 0);
    return total;
  };

  useEffect(() => {
    axios
      .post("/api", {
        test: "0",
      })
      .then((response) => {
        setFinalOptions(response.data.prestations);
        setBasePrice(response.data.infos.basePrice);
        setCloudPrice(response.data.infos.cloudPrice);
        setCommercialMail(response.data.infos.commercialMail);
        setProtectDataMessage(response.data.infos.protectDataMessage);
        setMailHypothesis(response.data.infos.mailHypothesis);
        setMailLegal(response.data.infos.mailLegal);
      })
      .catch((error) => {
        console.log("Erreur intro", error);
      });
  }, []);

  useEffect(() => {
    if (finalOptions !== []) {
      const arrayValues = [];
      //Sort option by position provided in database
      const sorted = finalOptions.sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      //Fill all the arrays
      sorted.forEach((finalOption) => {
        if (projectType === 1) {
          arrayValues.push(finalOption.options[0].price); //All debut prices if cloud project
        } else {
          if (
            finalOption.optionName !== "Emplacement" &&
            finalOption.optionName !==
              "Infrastructure, Opérations 24/7, 1 an d’engagement" &&
            finalOption.optionName !== "Infrastructure Cloud public" &&
            finalOption.optionName !== "Infrastructure Cloud privé" &&
            finalOption.optionName !== "Infrastructure On premise" &&
            finalOption.type !== 1
          ) {
            arrayValues.push(finalOption.options[0].price);
          } else {
            arrayValues.push(0);
          }
        }
      });
      setFinalValues(arrayValues);
      setFinalNames(
        Array.from(finalOptions, (finalOption) => finalOption.options[0].name)
      );
    }
  }, [finalOptions]);

  return (
    <Container>
      <Header />
      <form style={{ display: "flex", flexDirection: "column" }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0.5rem",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="name">
              Nom <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="name"
              required
              aria-required="true"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="email">
              Email{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="tel">
              Téléphone{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
              type="tel"
              maxLength={10}
              name="tel"
              required
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0.5rem",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="societe">
              Societé{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="societe"
              required
              value={societe}
              onChange={(e) => setSociete(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="fonction">
              Fonction{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="fonction"
              required
              value={fonction}
              onChange={(e) => setFonction(e.target.value)}
            />
          </Col>
          <Col></Col>
        </Row>

        <hr />
        {/** TYPE DE PROJET */}
        <Row
          style={{
            margin: "0.2rem 0",
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={4} sm={12}>
            <h6 style={{ fontWeight: "bold", margin: "1rem" }}>
              Type de projet :{" "}
            </h6>
          </Col>
          <Col
            lg={4}
            sm={12}
            style={{ display: "flex", alignItems: "center", margin: "1rem 0" }}
          >
            <input
              className="form-input"
              onChange={() => handleProjectType(0)}
              type="radio"
              value={projectType}
              name="projectType"
              checked={projectType === 0 ? true : false}
            />
            <label htmlFor={projectType}>
              <span style={{ fontSize: "1.1rem", marginRight: "2rem" }}>
                Conversion S/4HANA
              </span>
            </label>
          </Col>
          <Col
            lg={4}
            sm={12}
            style={{ display: "flex", alignItems: "center", margin: "1rem 0" }}
          >
            <input
              className="form-input"
              onChange={() => handleProjectType(1)}
              type="radio"
              value={projectType}
              name="projectType"
              checked={projectType === 1 ? true : false}
            />
            <label htmlFor={projectType}>
              <span style={{ fontSize: "1.1rem" }}>
                Conversion S/4HANA et migration Cloud
              </span>
            </label>
          </Col>
        </Row>

        <hr />

        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          {/** TAILLE OPTION */}
          <Row style={{ margin: "2rem 0" }} className="form-size">
            <Col></Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  borderBottom: "2px solid grey",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              >
                PETIT
              </span>
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  borderBottom: "2px solid grey",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              >
                MOYEN
              </span>
            </Col>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  borderBottom: "2px solid grey",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              >
                GRAND
              </span>
            </Col>
          </Row>

          {/** OPTIONS */}
          {finalOptions?.map((finalOption, i) => {
            //Not cloud
            if (
              finalOption.optionName !== "Infrastructure Cloud public" &&
              finalOption.optionName !== "Infrastructure Cloud privé" &&
              finalOption.optionName !== "Infrastructure On premise"
            ) {
              if (
                finalOption.optionName === "Emplacement" ||
                finalOption.optionName ===
                  "Infrastructure, Opérations 24/7, 1 an d’engagement" ||
                finalOption.type === 1
              ) {
                return (
                  <FormLine
                    key={finalOption.optionName}
                    optionName={finalOption.optionName}
                    options={finalOption.options}
                    value={finalNames[i]}
                    handleOption={handleFinalValues}
                    valueIdx={i}
                    possibleDisable={true}
                    isDisabled={projectType}
                  />
                );
              } else {
                return (
                  <FormLine
                    key={finalOption.optionName}
                    optionName={finalOption.optionName}
                    options={finalOption.options}
                    value={finalNames[i]}
                    handleOption={handleFinalValues}
                    valueIdx={i}
                  />
                );
              }
            }
          })}
        </div>

        <hr style={{ margin: "2rem 0" }} />

        <Row>
          <span style={{ marginBottom: "0.6rem" }}>
            J'accepte la protection des données personnelles :
          </span>
        </Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            style={{ marginRight: "0.3rem" }}
            onChange={() => setPrivateCheckbox((prev) => !prev)}
            type="checkbox"
            name="private_data"
          />
          <textarea
            readOnly
            defaultValue={protectDataMessage}
            style={{
              width: "100%",
              fontSize: "0.7rem",
              border: "1px solid grey",
              padding: "1rem 0.5rem",
              color: "grey",
            }}
          />
        </div>
        <div className="simulate-div">
          <button onClick={submitForm} className="simulate-button">
            SIMULER UNE ESTIMATION DU PROJET
          </button>
        </div>
      </form>
    </Container>
  );
};

export default Form;
