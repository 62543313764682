import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "../../axios";
import AWSFormLine from "./AWSFormLine";
import AWSHeader from "./AWSHeader";
import FormLine from "../Azure/FormLine";

const AWSForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [societe, setSociete] = useState("");
  const [fonction, setFonction] = useState("");
  const [privateCheckbox, setPrivateCheckbox] = useState(false);

  const [protectDataMessage, setProtectDataMessage] = useState("");
  const [commercialMail, setCommercialMail] = useState("");
  const [mailHypothesis, setMailHypothesis] = useState("");
  const [mailLegal, setMailLegal] = useState("");

  const [finalOptions, setFinalOptions] = useState([]);
  const [finalValues, setFinalValues] = useState([]);
  const [finalNames, setFinalNames] = useState([]);

  const [valX, setValX] = useState(0);
  const [valY, setValY] = useState(0);

  const [finalQuestions, setFinalQuestions] = useState([]);
  const [finalQuestionsValues, setFinalQuestionsValues] = useState([]);
  const [finalQuestionsNames, setFinalQuestionsNames] = useState([]);

  //Z System
  const [syst1, setSyst1] = useState("");
  const [syst2, setSyst2] = useState("");
  const [syst3, setSyst3] = useState("");

  const [basePrice, setBasePrice] = useState(0);

  const handleFinalValues = (idx, price, choiceName, optName) => {
    const newFinalValues = [...finalValues];
    const newFinalNames = [...finalNames];
    newFinalValues[idx] = price;
    newFinalNames[idx] = choiceName;
    setFinalValues(newFinalValues);
    setFinalNames(newFinalNames);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!name) {
      alert("Veuillez saisir un nom");
      return false;
    }
    if (!email) {
      alert("Veuillez saisir un email");
      return false;
    } else if (
      !email.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
    ) {
      alert("Veuillez renseigner une adresse mail correcte.");
      return false;
    }

    if (!tel) {
      alert("Veuillez saisir un numéro de téléphone");
      return false;
    } else if (!tel.toString().match(/0[0-9]{9}/)) {
      alert("Veuillez renseigner un téléphone au format : 0x xx xx xx xx");
      return false;
    }
    if (!societe) {
      alert("Nom de société non renséigné");
      return false;
    }
    if (!fonction) {
      alert("Fonction non renseignée");
      return false;
    }

    if (!privateCheckbox) {
      e.preventDefault();
      alert("Veuillez accepter la protection des données personnelles");
    } else {
      let optionsChoosed = [];
      Array.from(Array(finalNames.length)).forEach((value, i) => {
        optionsChoosed[i] = {
          name: finalOptions[i].optionName,
          option: finalNames[i],
        };
      });

      const finalPrice = sumTotalAmount() + basePrice;

      let systXYText = "";
      if (finalPrice < valX) {
        systXYText = `inférieure à ${valX.toLocaleString()} €`;
      } else if (finalPrice >= valX && finalPrice <= valY) {
        systXYText = `comprise entre ${valX.toLocaleString()} € et ${valY.toLocaleString()} €`;
      } else if (finalPrice > valY) {
        systXYText = `supérieure à ${valY.toLocaleString()} €`;
      }

      let systZText = "";

      const sumQuestionsValues = finalQuestionsValues.reduce(
        (acc, value) => acc + value,
        0
      );

      const valueSystem = Math.round(
        sumQuestionsValues / finalQuestionsValues.length
      );

      if (valueSystem === 1) {
        systZText = syst1;
      } else if (valueSystem === 2) {
        systZText = syst2;
      } else if (valueSystem === 3) {
        systZText = syst3;
      }

      console.log(systZText);

      alert(`L’estimation de votre projet a été envoyé à votre adresse mail.`);
      await axios
        .post("/aws/", {
          name,
          email,
          tel,
          societe,
          fonction,
          finalPrice,
          optionsChoosed,
          commercialMail,
          mailHypothesis,
          mailLegal,
          systXYText,
          systZText,
          finalQuestionsNames,
          finalQuestions,
        })
        .then((response) => {
          setName("");
          setEmail("");
          setTel("");
          setSociete("");
          setFonction("");
        })
        .catch((error) => {
          console.log("ERREUR POST CLIENT");
        });
    }
  };

  const sumTotalAmount = () => {
    const prices = Object.values(finalValues);
    const total = prices.reduce((base, acc) => base + acc, 0);
    return total;
  };

  const handleQuestionsChange = (idx, choiceName, optName, iValue) => {
    const newFinalNames = [...finalQuestionsNames];
    newFinalNames[idx] = choiceName;
    setFinalQuestionsNames(newFinalNames);
    const newFinalValues = [...finalQuestionsValues];
    newFinalValues[idx] = iValue;
    setFinalQuestionsValues(newFinalValues);
  };

  useEffect(() => {
    axios
      .post("/aws/api", {
        test: "0",
      })
      .then((response) => {
        setFinalOptions(response.data.prestations);
        setCommercialMail(response.data.infos.commercialMail);
        setProtectDataMessage(response.data.infos.protectDataMessage);
        setMailHypothesis(response.data.infos.mailHypothesis);
        setMailLegal(response.data.infos.mailLegal);
        setValX(response.data.infos.valX);
        setValY(response.data.infos.valY);
        setFinalQuestions(response.data.questions);
        setSyst1(response.data.infos.syst1);
        setSyst2(response.data.infos.syst2);
        setSyst3(response.data.infos.syst3);
        setBasePrice(response.data.infos.basePrice);
      })
      .catch((error) => {
        console.log("Erreur intro", error);
      });
  }, []);

  useEffect(() => {
    if (finalOptions !== []) {
      const arrayValues = [];
      //Sort option by position provided in database
      const sorted = finalOptions.sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      //Fill all the arrays
      sorted.forEach((finalOption) => {
        arrayValues.push(0);
      });
      setFinalValues(arrayValues);
      setFinalNames(
        Array.from(finalOptions, (finalOption) => finalOption.options[0].name)
      );
    }
  }, [finalOptions]);

  useEffect(() => {
    if (finalQuestions !== []) {
      const sorted = finalQuestions.sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      setFinalQuestions(sorted);
      setFinalQuestionsValues(Array.from(finalQuestions).map((question) => 1));
      setFinalQuestionsNames(
        Array.from(
          finalQuestions,
          (finalQuestion) => finalQuestion.choices[0].name
        )
      );
    }
  }, [finalQuestions]);

  return (
    <Container>
      <AWSHeader />
      <form style={{ display: "flex", flexDirection: "column" }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0.5rem",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="name">
              Nom <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="name"
              required
              aria-required="true"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="email">
              Email{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="tel">
              Téléphone{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
              type="tel"
              maxLength={10}
              name="tel"
              required
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0.5rem",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="societe">
              Societé{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="societe"
              required
              value={societe}
              onChange={(e) => setSociete(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="fonction">
              Fonction{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="fonction"
              required
              value={fonction}
              onChange={(e) => setFonction(e.target.value)}
            />
          </Col>
          <Col></Col>
        </Row>
        <br />
        <h1 style={{ textAlign: "center", color: "#f28024" }}>
          Questionnaire Conversion S/4HANA
        </h1>
        <br />

        {/** OPTIONS */}
        {finalOptions?.map((finalOption, i) => {
          return (
            <FormLine
              key={finalOption.optionName}
              optionName={finalOption.optionName}
              options={finalOption.options}
              value={finalNames[i]}
              handleOption={handleFinalValues}
              valueIdx={i}
            />
          );
        })}

        <hr style={{ margin: "2rem 0" }} />

        {/** QUESTION */}
        <h1 style={{ textAlign: "center", color: "#f28024" }}>
          Définition de l’architecture cloud
        </h1>
        <br />

        {finalQuestions?.map((finalQuestion, idx) => {
          return (
            <AWSFormLine
              key={finalQuestion.question}
              optionName={finalQuestion.question}
              options={finalQuestion.choices}
              value={finalQuestionsNames[idx]}
              handleOption={handleQuestionsChange}
              valueIdx={idx}
            />
          );
        })}

        <hr style={{ margin: "2rem 0" }} />

        <Row>
          <span style={{ marginBottom: "0.6rem" }}>
            J'accepte la protection des données personnelles :
          </span>
        </Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            style={{ marginRight: "0.3rem" }}
            onChange={() => setPrivateCheckbox((prev) => !prev)}
            type="checkbox"
            name="private_data"
          />
          <textarea
            readOnly
            defaultValue={protectDataMessage}
            style={{
              width: "100%",
              fontSize: "0.7rem",
              border: "1px solid grey",
              padding: "1rem 0.5rem",
              color: "grey",
            }}
          />
        </div>
        <div className="simulate-div">
          <button onClick={submitForm} className="simulate-button">
            SIMULER UNE ESTIMATION DU PROJET
          </button>
        </div>
      </form>
    </Container>
  );
};

export default AWSForm;
