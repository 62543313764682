import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "../../axios";
import GeneralFormLine from "./GeneralFormLine";
import GeneralHeader from "./GeneralHeader";
import FormLine from "../Azure/FormLine";

const GeneralForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [societe, setSociete] = useState("");
  const [fonction, setFonction] = useState("");
  const [privateCheckbox, setPrivateCheckbox] = useState(false);

  const [protectDataMessage, setProtectDataMessage] = useState("");
  const [commercialMail, setCommercialMail] = useState("");
  const [mailHypothesis, setMailHypothesis] = useState("");
  const [mailLegal, setMailLegal] = useState("");
  const [hebergeurMessage, setHebergeurMessage] = useState("");

  const [finalOptions, setFinalOptions] = useState([]);
  const [finalValues, setFinalValues] = useState([]);
  const [finalNames, setFinalNames] = useState([]);

  const [valX, setValX] = useState(0);
  const [valY, setValY] = useState(0);

  const [finalQuestions, setFinalQuestions] = useState([]);
  const [finalQuestionsValues, setFinalQuestionsValues] = useState([]);
  const [finalQuestionsNames, setFinalQuestionsNames] = useState([]);

  //Hebergeurs
  const [hebergeurs, setHebergeurs] = useState([]);
  const [chosenHebergeurs, setChosenHebergeurs] = useState([]);
  const [chosenHebergeursText, setChosenHebergeursText] = useState([]);

  const [basePrice, setBasePrice] = useState(0);

  const handleFinalValues = (idx, price, choiceName, optName) => {
    const newFinalValues = [...finalValues];
    const newFinalNames = [...finalNames];
    newFinalValues[idx] = price;
    newFinalNames[idx] = choiceName;
    setFinalValues(newFinalValues);
    setFinalNames(newFinalNames);
  };

  const getHebergeurIndexByName = (name) => {
    return hebergeurs.findIndex((hebergeur) => hebergeur.name === name);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!name) {
      alert("Veuillez saisir un nom");
      return false;
    }
    if (!email) {
      alert("Veuillez saisir un email");
      return false;
    } else if (
      !email.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
    ) {
      alert("Veuillez renseigner une adresse mail correcte.");
      return false;
    }

    if (!tel) {
      alert("Veuillez saisir un numéro de téléphone");
      return false;
    } else if (!tel.toString().match(/0[0-9]{9}/)) {
      alert("Veuillez renseigner un téléphone au format : 0x xx xx xx xx");
      return false;
    }
    if (!societe) {
      alert("Nom de société non renséigné");
      return false;
    }
    if (!fonction) {
      alert("Fonction non renseignée");
      return false;
    }

    if (!privateCheckbox) {
      e.preventDefault();
      alert("Veuillez accepter la protection des données personnelles");
    } else {
      let optionsChoosed = [];
      Array.from(Array(finalNames.length)).forEach((value, i) => {
        optionsChoosed[i] = {
          name: finalOptions[i].optionName,
          option: finalNames[i],
        };
      });

      const finalPrice = sumTotalAmount() + basePrice; //Final price (calculated price + base price from db)

      //Determination of text to be sent
      let systXYText = "";

      if (finalPrice < valX) {
        systXYText = `inférieure à ${valX.toLocaleString()} €`;
      } else if (finalPrice >= valX && finalPrice <= valY) {
        systXYText = `comprise entre ${valX.toLocaleString()} € et ${valY.toLocaleString()} €`;
      } else if (finalPrice > valY) {
        systXYText = `supérieure à ${valY.toLocaleString()} €`;
      }

      let hebergeursTextArray = [];

      // Calculate the values for t hsirt size 1+2+3+2+1 etc
      const sumQuestionsValues = finalQuestionsValues.reduce(
        (acc, value) => acc + value,
        0
      );

      // Get average rounded 1 2 or 3
      const valueSystem = Math.round(
        sumQuestionsValues / finalQuestionsValues.length
      );

      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const hebergeurSize = hebergeurs.length;
      let minimumSizeArray = new Array(hebergeurSize);

      finalQuestions.forEach((finalQuestions, questionIndex) => {
        const tShirtNumberOfOneQuestion = finalQuestionsValues[questionIndex]; //1, 2, 3
        const hebergeursDispoForTheQuestion = finalQuestions.choices[
          tShirtNumberOfOneQuestion - 1
        ].h
          .split(";")
          .map((heber) => heber);

        if (hebergeursDispoForTheQuestion.length <= minimumSizeArray.length) {
          minimumSizeArray = hebergeursDispoForTheQuestion;
        }
      });

      let intersectionArray = minimumSizeArray; //At initialization , intersectionArray takes the minimumArray as it's the smallest array

      finalQuestions.forEach((finalQuestions, questionIndex) => {
        const tShirtNumberOfOneQuestion = finalQuestionsValues[questionIndex]; //1, 2, 3
        const hebergeursDispoForTheQuestion = finalQuestions.choices[
          tShirtNumberOfOneQuestion - 1
        ].h
          .split(";")
          .map((heber) => heber);

        // Find intersection
        intersectionArray = hebergeursDispoForTheQuestion.filter((h) =>
          intersectionArray.includes(h)
        );
      });

      //Loop the interactions array
      intersectionArray.forEach((heber) => {
        if (chosenHebergeursText.includes(heber)) {
          const hebergeurIndex = getHebergeurIndexByName(heber);

          const hebergeurToPush = hebergeurs[hebergeurIndex][`s${valueSystem}`]; //Response depending on the t-shirt size result
          //Add its text to the array
          hebergeursTextArray.push(hebergeurToPush);
        }
      });

      console.log(hebergeursTextArray);

      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // finalQuestions.forEach((finalQuestion) => {
      //   //Map for each question, and each compatible hebergeur

      //   finalQuestion.choices[valueSystem - 1].h.split(";").map((h1) => {
      //     //Is hebergeur is checked
      //     if (chosenHebergeursText.includes(h1)) {
      //       const hebergeurIndex = getHebergeurIndexByName(h1); //Get its index

      //       const hebergeurToPush =
      //         hebergeurs[hebergeurIndex][`s${valueSystem}`]; //Response depending on the t-shirt size result

      //       hebergeursTextArray.push(hebergeurToPush);
      //     }
      //   });
      // });

      // //Count obj for hebergeur response
      // const hebergeursTextArrayObj = {};

      // for (const element of hebergeursTextArray) {
      //   if (hebergeursTextArrayObj[element]) {
      //     hebergeursTextArrayObj[element] += 1;
      //   } else {
      //     hebergeursTextArrayObj[element] = 1;
      //   }
      // }

      // const finalHebergeursTextArray = [];

      // //If the response occurs the same amount of time with the number of questions --> means the hebergeur is set for each question
      // for (const hebergeur of hebergeurs) {
      //   const hebergeurResponse = hebergeur[`s${valueSystem}`];
      //   if (
      //     finalQuestions.length === hebergeursTextArrayObj[hebergeurResponse]
      //   ) {
      //     finalHebergeursTextArray.push(hebergeurResponse);
      //   }
      // }

      const selectedHebergeurs = chosenHebergeursText.join(","); // Create array of chosen hebergeurs text

      alert(`L’estimation de votre projet a été envoyé à votre adresse mail.`);

      await axios
        .post("/general/", {
          name,
          email,
          tel,
          societe,
          fonction,
          finalPrice,
          optionsChoosed,
          commercialMail,
          mailHypothesis,
          mailLegal,
          systXYText,
          finalQuestionsNames,
          finalQuestions,
          hebergeursTextArray,
          hebergeurMessage,
          selectedHebergeurs,
        })
        .then((response) => {
          setName("");
          setEmail("");
          setTel("");
          setSociete("");
          setFonction("");
        })
        .catch((error) => {
          console.log("ERREUR POST CLIENT FOR SENDING MAIL");
        });
    }
  };

  const sumTotalAmount = () => {
    const prices = Object.values(finalValues);
    const total = prices.reduce((base, acc) => base + acc, 0);
    return total;
  };

  const handleQuestionsChange = (idx, choiceName, optName, iValue) => {
    const newFinalNames = [...finalQuestionsNames];
    newFinalNames[idx] = choiceName;
    setFinalQuestionsNames(newFinalNames);
    const newFinalValues = [...finalQuestionsValues];
    newFinalValues[idx] = iValue;
    setFinalQuestionsValues(newFinalValues);
  };

  useEffect(() => {
    axios
      .post("/general/api", {
        test: "0",
      })
      .then((response) => {
        setFinalOptions(response.data.prestations);
        setCommercialMail(response.data.infos.commercialMail);
        setProtectDataMessage(response.data.infos.protectDataMessage);
        setMailHypothesis(response.data.infos.mailHypothesis);
        setMailLegal(response.data.infos.mailLegal);
        setValX(response.data.infos.valX);
        setValY(response.data.infos.valY);
        setFinalQuestions(response.data.questions);
        setHebergeurs(response.data.hebergeurs);
        setBasePrice(response.data.infos.basePrice);
        setHebergeurMessage(response.data.infos.hebergeurMessage);
      })
      .catch((error) => {
        console.log("Erreur intro", error);
      });
  }, []);

  const changeChosenHebergeur = (index) => {
    const newChosenHebergeurs = [...chosenHebergeurs];
    const newChosenHebergeursText = [];
    newChosenHebergeurs[index] = !newChosenHebergeurs[index];
    for (let i = 0; i < hebergeurs.length; i++) {
      if (newChosenHebergeurs[i]) {
        newChosenHebergeursText.push(hebergeurs[i].name);
      }
    }
    setChosenHebergeursText(newChosenHebergeursText);
    setChosenHebergeurs(newChosenHebergeurs);
  };

  useEffect(() => {
    if (finalOptions !== []) {
      const arrayValues = [];
      //Sort option by position provided in database
      const sorted = finalOptions.sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      //Fill all the arrays
      sorted.forEach((finalOption) => {
        arrayValues.push(0);
      });
      setFinalValues(arrayValues);
      setFinalNames(
        Array.from(finalOptions, (finalOption) => finalOption.options[0].name)
      );
    }
  }, [finalOptions]);

  useEffect(() => {
    if (finalQuestions !== []) {
      const sorted = finalQuestions.sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      setFinalQuestionsValues(Array.from(finalQuestions).map((question) => 1));
      setFinalQuestions(sorted);
      setFinalQuestionsNames(
        Array.from(
          finalQuestions,
          (finalQuestion) => finalQuestion.choices[0].name
        )
      );
    }
  }, [finalQuestions]);

  useEffect(() => {
    if (chosenHebergeurs !== [] && chosenHebergeursText !== []) {
      const newChosenHebergeurs = Array.from(hebergeurs).map(
        (hebergeur) => true
      );
      setChosenHebergeurs(newChosenHebergeurs);

      const newChosenHebergeursText = [...chosenHebergeursText];
      for (let i = 0; i < hebergeurs.length; i++) {
        if (newChosenHebergeurs[i]) {
          newChosenHebergeursText.push(hebergeurs[i].name);
        }
      }
      setChosenHebergeursText(newChosenHebergeursText);
    }
  }, [hebergeurs]);

  return (
    <Container>
      <GeneralHeader />
      <form style={{ display: "flex", flexDirection: "column" }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0.5rem",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="name">
              Nom <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="name"
              required
              aria-required="true"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="email">
              Email{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="tel">
              Téléphone{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
              type="tel"
              maxLength={10}
              name="tel"
              required
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0.5rem",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="societe">
              Societé{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="societe"
              required
              value={societe}
              onChange={(e) => setSociete(e.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <label htmlFor="fonction">
              Fonction{" "}
              <span style={{ color: "red", marginRight: "0.5rem" }}>*</span>
            </label>
            <input
              className="form-credantial"
              type="text"
              name="fonction"
              required
              value={fonction}
              onChange={(e) => setFonction(e.target.value)}
            />
          </Col>
          <Col></Col>
        </Row>
        <br />
        <h1 style={{ textAlign: "center", color: "#f28024" }}>
          Questionnaire Conversion S/4HANA
        </h1>
        <br />

        {/** OPTIONS */}
        {finalOptions?.map((finalOption, i) => {
          return (
            <FormLine
              key={finalOption.optionName}
              optionName={finalOption.optionName}
              options={finalOption.options}
              value={finalNames[i]}
              handleOption={handleFinalValues}
              valueIdx={i}
            />
          );
        })}

        <hr style={{ margin: "2rem 0" }} />

        <br />
        {/** QUESTION */}
        <h1 style={{ textAlign: "center", color: "#f28024" }}>
          Définition de l’architecture cloud
        </h1>
        <br />

        <h5 style={{ fontWeight: "bold", margin: "1rem 0" }}>
          Êtes-vous prêt à travailler avec :{" "}
        </h5>

        <div style={{ display: "flex" }}>
          {hebergeurs.map((hebergeur, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <input
                style={{ marginRight: "0.2rem" }}
                type="checkbox"
                name="hebergeur"
                value={hebergeur.id}
                checked={chosenHebergeurs[index]}
                onChange={() => changeChosenHebergeur(index)}
              />
              <label style={{ marginRight: "0.8rem" }} htmlFor="hebergeur">
                {hebergeur.name}
              </label>
            </div>
          ))}
        </div>

        <hr style={{ margin: "2rem 0" }} />

        {finalQuestions?.map((finalQuestion, idx) => {
          return (
            <GeneralFormLine
              key={finalQuestion.question}
              optionName={finalQuestion.question}
              options={finalQuestion.choices}
              value={finalQuestionsNames[idx]}
              handleOption={handleQuestionsChange}
              valueIdx={idx}
            />
          );
        })}

        <hr style={{ margin: "2rem 0" }} />

        <Row>
          <span style={{ marginBottom: "0.6rem" }}>
            J'accepte la protection des données personnelles :
          </span>
        </Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            style={{ marginRight: "0.3rem" }}
            onChange={() => setPrivateCheckbox((prev) => !prev)}
            type="checkbox"
            name="private_data"
          />
          <textarea
            readOnly
            defaultValue={protectDataMessage}
            style={{
              width: "100%",
              fontSize: "0.7rem",
              border: "1px solid grey",
              padding: "1rem 0.5rem",
              color: "grey",
            }}
          />
        </div>
        <div className="simulate-div">
          <button onClick={submitForm} className="simulate-button">
            SIMULER UNE ESTIMATION DU PROJET
          </button>
        </div>
      </form>
    </Container>
  );
};

export default GeneralForm;
