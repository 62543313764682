import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";

import axios from "../../axios";

import logo from "../../images/logo.JPG";

const Header = () => {
  const history = useNavigate();
  const location = useLocation();

  const [intro, setIntro] = useState("");

  const handleClick = () => {
    if (location.pathname === "/admin") {
      history("/");
      window.location.reload();
    }
  };

  useEffect(() => {
    axios
      .post("/api", {
        test: "0",
      })
      .then((response) => {
        setIntro(response.data?.infos?.introMessage);
      })
      .catch((error) => {
        console.log("Erreur intro", error);
      });
  }, []);

  return (
    <div>
      <Row
        style={{
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col style={{ margin: "1.2rem", backgroundColor: "white" }}>
          <img src={logo} alt="logo" width="30%"></img>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          {location.pathname === "/admin" && (
            <Button
              style={{
                alignSelf: "end",
                backgroundColor: "#f28024",
                outline: "none",
                border: "none",
                margin: "1rem",
                fontSize: "1.3rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleClick}
            >
              <AiFillHome style={{ marginRight: "0.3rem" }} />
              Accueil
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          {(location.pathname === "/azure" || location.pathname === "/aws") && (
            <h1
              style={{
                fontWeight: "bold",
                color: "#f28024",
                border: "3px solid #f28024",
                padding: "0.5rem 2rem",
                textAlign: "center",
                margin: "0.3rem",
              }}
            >
              {"SIMULATEUR Go2S/4HANA & Go2Cloud"}
            </h1>
          )}
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          fontStyle: "italic",
        }}
      >
        <p
          style={{
            color: "grey",
            margin: "2rem 0",
            fontSize: "0.9rem",
            whiteSpace: "pre-wrap",
          }}
        >
          {(location.pathname === "/azure" || location.pathname === "/aws") &&
            intro}
        </p>
      </Row>
    </div>
  );
};

export default Header;
