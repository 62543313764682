import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../axios";
import { FiSave } from "react-icons/fi";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";

import "../Admin.css";

const AzureAdmin = () => {
  //BASIC INFOS
  const [commercialMail, setCommercialMail] = useState("");
  const [introText, setIntroText] = useState("");
  const [basePrice, setBasePrice] = useState(0);
  const [cloudPrice, setCloudPrice] = useState(0);
  const [finalOptions, setFinalOptions] = useState(null);

  const [protectDataMessage, setProtectDataMessage] = useState("");
  const [mailHypothesis, setMailHypothesis] = useState("");
  const [mailLegal, setMailLegal] = useState("");

  //UI RENDERING LOGIC
  const [show, setShow] = useState(false);

  //PRESTATION
  const [newPrestaName, setNewPrestaName] = useState("");
  const [newPrestaOption1, setNewPrestaOption1] = useState("");
  const [newPrestaPrice1, setNewPrestaPrice1] = useState(null);
  const [newPrestaOption2, setNewPrestaOption2] = useState("");
  const [newPrestaPrice2, setNewPrestaPrice2] = useState(null);
  const [newPrestaOption3, setNewPrestaOption3] = useState("");
  const [newPrestaPrice3, setNewPrestaPrice3] = useState(null);
  const [projectType, setProjectType] = useState(0);
  const [newPosition, setNewPosition] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  //HANDLE PROJECT TYPE WHEN ADDING NEW OPTION
  const handleProjectType = (type) => {
    if (type === 0) {
      setProjectType(0);
    } else {
      setProjectType(1);
    }
  };

  //CONNECT TO THA ADMIN PAGE
  const getInitData = async () => {
    await axios
      .post("/data")
      .then((response) => {
        setIntroText(response.data.infos.introMessage);
        setCommercialMail(response.data.infos.commercialMail);
        setFinalOptions(
          response.data.prestations.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
        setBasePrice(response.data.infos.basePrice);
        setCloudPrice(response.data.infos.cloudPrice);
        setProtectDataMessage(response.data.infos.protectDataMessage);
        setMailHypothesis(response.data.infos.mailHypothesis);
        setMailLegal(response.data.infos.mailLegal);
      })
      .catch((error) => {
        alert("Erreur fetching Azure data");
      });
  };

  function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }

  const addPresta = async (e) => {
    e.preventDefault();
    if (
      !newPrestaName ||
      !newPrestaOption1 ||
      !newPrestaPrice1 ||
      !newPrestaOption2 ||
      !newPrestaPrice2 ||
      !newPrestaOption3 ||
      !newPrestaPrice3 ||
      !newPosition
    ) {
      alert("Un des champs n'est pas rempli");
      return;
    }
    const positionsArray = finalOptions.map((option) =>
      parseInt(option.position)
    );

    if (positionsArray.includes(parseInt(newPosition))) {
      alert("Cette position a déjà été choisie");
      return;
    }
    await axios
      .post("/prestation", {
        newPrestaName,
        newPrestaOption1,
        newPrestaPrice1,
        newPrestaOption2,
        newPrestaPrice2,
        newPrestaOption3,
        newPrestaPrice3,
        projectType,
        newPosition,
      })
      .then((response) => {
        setShow(false);
        setNewPrestaName("");
        setNewPrestaOption1("");
        setNewPrestaPrice1(null);
        setNewPrestaOption2("");
        setNewPrestaPrice2(null);
        setNewPrestaOption3("");
        setNewPrestaPrice3(null);
        setFinalOptions(
          response.data.finalOptions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
        alert(`Nouvelle option ${newPrestaName} ajoutée !`);
      })
      .catch((error) => {
        console.log("Error adding presta");
      });
  };

  const deletePresta = async (e, id) => {
    e.preventDefault();
    let optionObj = {};
    finalOptions.forEach((finalOption) => {
      if (finalOption.id === id) {
        optionObj = finalOption;
      }
    });

    const optionName = optionObj.optionName;
    var answer = window.confirm(
      `Voulez-vous vraiment supprimer l'option ${optionName} ?`
    );
    if (answer) {
      await axios
        .delete("/prestation", {
          data: {
            id,
          },
        })
        .then((response) => {
          alert(`Option ${optionName} supprimée !`);
          setFinalOptions(
            response.data.finalOptions.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
        })
        .catch((error) => {
          console.log("Error");
        });
    } else {
    }
  };

  const updatePresta = async (e, id) => {
    e.preventDefault();

    let optionObj = {};
    finalOptions.forEach((finalOption) => {
      if (finalOption.id === id) {
        optionObj = finalOption;
      }
    });

    if (!optionObj) {
      console.error("Erreur option obj");
      return;
    }

    const positionsArray = finalOptions.map((option) =>
      parseInt(option.position)
    );

    if (hasDuplicates(positionsArray)) {
      alert("Cette position a déjà été choisie");
      return;
    }

    const optionName = optionObj.optionName;
    const name1 = optionObj.options[0].name;
    const price1 = optionObj.options[0].price;
    const name2 = optionObj.options[1].name;
    const price2 = optionObj.options[1].price;
    const name3 = optionObj.options[2].name;
    const price3 = optionObj.options[2].price;
    const position = optionObj.position;

    await axios
      .put("/prestation", {
        id,
        newPrestaName: optionName,
        newPrestaOption1: name1,
        newPrestaPrice1: price1,
        newPrestaOption2: name2,
        newPrestaPrice2: price2,
        newPrestaOption3: name3,
        newPrestaPrice3: price3,
        newPosition: position,
      })
      .then((response) => {
        alert(`Option ${optionName} modifiée`);
        setFinalOptions(
          response.data.finalOptions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
      })
      .catch((error) => console.error(error));
  };

  const changePrestaPrice = (price, optionName, i, j) => {
    const finalOptionsThree = finalOptions[i]?.options;
    const newFinalOptionsThree = [];
    const test = parseFloat(price);
    finalOptionsThree?.forEach((finalOptionOne, k) => {
      if (k === j) {
        const name = finalOptionOne.name;
        const obj = {
          name,
          price: test,
        };
        newFinalOptionsThree.push(obj);
      } else {
        newFinalOptionsThree.push(finalOptionOne);
      }
    });
    const newFinalOptions = [...finalOptions];
    newFinalOptions[i] = {
      ...newFinalOptions[i],
      options: newFinalOptionsThree,
    };
    setFinalOptions(newFinalOptions);
  };

  const changePrestaName = (name, optionName, i, j) => {
    const finalOptionsThree = finalOptions[i]?.options;
    const newFinalOptionsThree = [];
    finalOptionsThree?.forEach((finalOptionOne, k) => {
      if (k === j) {
        const price = finalOptionOne.price;
        const obj = {
          name,
          price,
        };
        newFinalOptionsThree.push(obj);
      } else {
        newFinalOptionsThree.push(finalOptionOne);
      }
    });
    const newFinalOptions = [...finalOptions];
    newFinalOptions[i] = {
      ...newFinalOptions[i],
      options: newFinalOptionsThree,
    };
    setFinalOptions(newFinalOptions);
  };

  const changeOptionName = (newName, i) => {
    const newFinalOptions = [...finalOptions];
    newFinalOptions[i].optionName = newName;
    setFinalOptions(newFinalOptions);
  };

  const changeOptionPosition = (newPos, i) => {
    const newFinalOptions = [...finalOptions];

    newFinalOptions[i].position = newPos;
    setFinalOptions(newFinalOptions);
  };

  const changeCommercialMail = async (e) => {
    e.preventDefault();
    const commercialMails = commercialMail.trim().split(";");
    let correctMail = true;
    commercialMails.forEach((mail) => {
      if (
        !mail.match(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
      ) {
        correctMail = false;
      }
    });
    if (!commercialMail) {
      alert("Veuillez saisir un email");
      return false;
    } else if (!correctMail) {
      alert("Veuillez renseigner une adresse mail correcte.");
      return false;
    }
    await axios
      .put("/commercialMail", {
        newCommercialMail: commercialMail,
      })
      .then((response) => {
        alert(`Mail commercial changé en ${commercialMail}`);
        setCommercialMail(response.data.newCommercialMail);
      })
      .catch((error) => console.error(error));
  };

  const changeIntroMessage = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!introText) {
      answer = window.confirm(
        "Attention, votre champ intitulé de la prestation est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || introText) {
      await axios
        .put("/introMessage", {
          newIntroMessage: introText,
        })
        .then((response) => {
          alert("Intitulé de la prestation changé !");
          setIntroText(response.data.newIntroMessage);
        })
        .catch((err) => console.error(err));
    }
  };

  const changeProtectDataMessage = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!protectDataMessage) {
      answer = window.confirm(
        "Attention, votre champ intitulé protection des données personnelles est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || protectDataMessage) {
      await axios
        .put("/protectDataMessage", {
          newProtectDataMessage: protectDataMessage,
        })
        .then((response) => {
          alert("Intitulé protection des données changé !");
          setProtectDataMessage(response.data.newProtectDataMessage);
        })
        .catch((err) => console.error(err));
    }
  };

  const changeMailHypothesis = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!mailHypothesis) {
      answer = window.confirm(
        "Attention, votre texte pour 'HYPOTHESES ET EXCLUSIONS' est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || mailHypothesis) {
      await axios
        .put("/mailHypothesis", {
          newMailHypothesis: mailHypothesis,
        })
        .then((response) => {
          alert("Texte hypothèses et exclusions changé !");
          setMailHypothesis(response.data.newMailHypothesis);
        })
        .catch((err) => console.error(err));
    }
  };

  const changeMailLegal = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!mailLegal) {
      answer = window.confirm(
        "Attention, votre texte pour 'MENTIONS LEGALES' est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || mailLegal) {
      await axios
        .put("/mailLegal", {
          newMailLegal: mailLegal,
        })
        .then((response) => {
          alert("Texte mentions legales changé !");
          setMailLegal(response.data.newMailLegal);
        })
        .catch((err) => console.error(err));
    }
  };

  const changePrices = async (e) => {
    e.preventDefault();

    await axios
      .put("/prices", {
        basePrice,
        cloudPrice,
      })
      .then((response) => {
        alert("Les prix ont été changés !");
        setBasePrice(response.data.basePrice);
        setCloudPrice(response.data.cloudPrice);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (finalOptions) {
      const filtered = finalOptions.filter((option) => option.position <= 900); //Remove 900+ positions for cloud
      const positionsArray = filtered.map((x) => x.position); //Get only positions
      setNewPosition(Math.max(...positionsArray) + 1);
    }
  }, [finalOptions]);

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      <div className="admin-div">
        <h1
          style={{
            fontWeight: "bold",
            color: "#f28024",
            border: "3px solid #f28024",
            padding: "0.5rem 2rem",
            textAlign: "center",
            margin: "2rem",
            fontSize: "3rem",
          }}
        >
          FORMULAIRE Azure
        </h1>
        <form className="admin-form">
          <label
            style={{
              margin: "1rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
            htmlFor="commercialMail"
          >
            Mail commercial :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Si vous souhaitez envoyer la prestation à plusieurs mails, mettez un
            ";" entre chaque adresse
          </span>
          <span style={{ fontStyle: "italic", fontSize: "0.7rem" }}>
            Exemple: mail1@mail.com;mail2@mail.com
          </span>
          <br />
          <input
            style={{ padding: "0.3rem" }}
            type="text"
            name="commercialMail"
            value={commercialMail}
            onChange={(e) => setCommercialMail(e.target.value)}
          />
          <button className="admin-button" onClick={changeCommercialMail}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau mail
          </button>

          <label
            style={{
              margin: "1rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
            htmlFor="introText"
          >
            Intitulé prestation :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Cet intitulé correspond au texte d'introduction situé au tout début
            de la simulation.
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={introText}
            onChange={(e) => setIntroText(e.target.value)}
            rows={5}
          />
          <button className="admin-button" onClick={changeIntroMessage}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau message
          </button>

          <label style={{ fontSize: "1.1rem" }} htmlFor="basePrice">
            Montant de base{" "}
            <span style={{ fontWeight: "bold" }}>Go2S/4HANA (€) :</span>
            <br />
          </label>
          <input
            type="number"
            pattern="..."
            step="any"
            name="basePrice"
            value={basePrice}
            onChange={(e) => setBasePrice(e.target.value)}
          />
          <br />
          <label style={{ fontSize: "1.1rem" }} htmlFor="cloudPrice">
            Montant de base{" "}
            <span style={{ fontWeight: "bold" }}>Go2Cloud (€) :</span>
            <br />
          </label>
          <input
            type="number"
            pattern="..."
            step="any"
            name="cloudPrice"
            value={cloudPrice}
            onChange={(e) => setCloudPrice(e.target.value)}
          />

          <button className="admin-button" onClick={changePrices}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer les prix
          </button>

          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />
          <span
            style={{
              margin: "1rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
          >
            Options :{" "}
          </span>
          <table style={{ border: "1px solid black" }}>
            <tbody>
              <tr style={{ border: "1px solid black" }}>
                <th style={{ padding: "1rem" }}>Nom</th>
                <th>Position (entre 0 & 900)</th>
                <th>Petit</th>
                <th>Moyen</th>
                <th>Grand</th>
                <th>Type</th>
              </tr>
              {finalOptions?.map((finalOption, i) => {
                return (
                  <tr style={{ border: "1px solid gray" }} key={i}>
                    <td style={{ padding: "0.5rem" }}>
                      <textarea
                        style={{ fontSize: "0.8rem", resize: "none" }}
                        rows={4}
                        value={finalOption.optionName}
                        onChange={(e) => changeOptionName(e.target.value, i)}
                      />
                    </td>
                    <td style={{ padding: "0.5rem" }}>
                      <input
                        style={{ fontSize: "0.8rem", resize: "none" }}
                        rows={2}
                        value={`${
                          finalOption.optionName ===
                            "Infrastructure Cloud public" ||
                          finalOption.optionName ===
                            "Infrastructure Cloud privé" ||
                          finalOption.optionName === "Infrastructure On premise"
                            ? ""
                            : finalOption.position
                        }`}
                        onChange={(e) =>
                          changeOptionPosition(e.target.value, i)
                        }
                        disabled={
                          finalOption.optionName ===
                            "Infrastructure Cloud public" ||
                          finalOption.optionName ===
                            "Infrastructure Cloud privé" ||
                          finalOption.optionName === "Infrastructure On premise"
                            ? true
                            : false
                        }
                        readOnly={
                          finalOption.optionName ===
                            "Infrastructure Cloud public" ||
                          finalOption.optionName ===
                            "Infrastructure Cloud privé" ||
                          finalOption.optionName === "Infrastructure On premise"
                            ? true
                            : false
                        }
                      />
                    </td>
                    {finalOption?.options.map((option, j) => (
                      <td key={`${j}${option}`}>
                        <textarea
                          style={{ fontSize: "0.65rem", fontWeight: "bold" }}
                          rows={2}
                          value={`${
                            (
                              finalOption.optionName ===
                                "Infrastructure Cloud public" ||
                              finalOption.optionName ===
                                "Infrastructure Cloud privé" ||
                              finalOption.optionName ===
                                "Infrastructure On Premise"
                                ? true
                                : false
                            )
                              ? ""
                              : option.name
                          }`}
                          onChange={(e) =>
                            changePrestaName(
                              e.target.value,
                              finalOption.optionName,
                              i,
                              j
                            )
                          }
                          disabled={
                            finalOption.optionName ===
                              "Infrastructure Cloud public" ||
                            finalOption.optionName ===
                              "Infrastructure Cloud privé" ||
                            finalOption.optionName ===
                              "Infrastructure On Premise"
                              ? true
                              : false
                          }
                          readOnly={
                            finalOption.optionName ===
                              "Infrastructure Cloud public" ||
                            finalOption.optionName ===
                              "Infrastructure Cloud privé" ||
                            finalOption.optionName ===
                              "Infrastructure On Premise"
                              ? true
                              : false
                          }
                        />
                        <input
                          style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          disabled={
                            finalOption.optionName ===
                              "Infrastructure, Opérations 24/7, 1 an d’engagement" ||
                            finalOption.optionName === "Emplacement"
                              ? true
                              : false
                          }
                          readOnly={
                            finalOption.optionName ===
                              "Infrastructure, Opérations 24/7, 1 an d’engagement" ||
                            finalOption.optionName === "Emplacement"
                              ? true
                              : false
                          }
                          type="number"
                          pattern="..."
                          step="any"
                          value={`${option.price}`}
                          onChange={(e) =>
                            changePrestaPrice(
                              e.target.value,
                              finalOption.optionName,
                              i,
                              j
                            )
                          }
                        />
                        <span> €</span>
                      </td>
                    ))}
                    <td
                      style={{
                        padding: "0.5rem",
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        color: finalOption.type === 0 ? "#00A99A" : "#232C4B",
                      }}
                    >
                      {finalOption.type === 0 ? "Go2S/4HANA" : "Go2Cloud"}
                    </td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{ fontSize: "0.7rem" }}
                        className="admin-deleteButton"
                        onClick={(e) => deletePresta(e, finalOption.id)}
                      >
                        <MdDelete style={{ marginRight: "0.3rem" }} />
                        Supprimer
                      </button>
                      <button
                        style={{ fontSize: "0.7rem" }}
                        className="admin-button"
                        onClick={(e) => updatePresta(e, finalOption.id)}
                      >
                        <FiSave style={{ marginRight: "0.3rem" }} />
                        Enregistrer
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="admin-button" onClick={handleShow}>
            <IoIosAddCircle style={{ marginRight: "0.3rem" }} />
            Ajouter une option
          </button>
          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />
          <label
            style={{
              margin: "1rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
            htmlFor="dataProtectMessage"
          >
            Intitulé protection des données personnelles :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Cet intitulé correspond au texte présent dans le champ de texte de
            protection des données personnelles.
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={protectDataMessage}
            onChange={(e) => setProtectDataMessage(e.target.value)}
            rows={15}
          />
          <button className="admin-button" onClick={changeProtectDataMessage}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouvel intitulé
          </button>

          <label
            style={{
              margin: "1rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
            htmlFor="mailHypothesis"
          >
            Texte "HYPOTHESES ET EXCLUSIONS" du mail de réponse :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Ce texte correspond au paragraphe "HYPOTHESES ET EXCLUSIONS" présent
            dans le mail automatique
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            Pour créer une liste à puces, veuillez insérer un "-" avant
            l'élement
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={mailHypothesis}
            onChange={(e) => setMailHypothesis(e.target.value)}
            rows={20}
          />
          <button className="admin-button" onClick={changeMailHypothesis}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau texte
          </button>

          <label
            style={{
              margin: "1rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
            htmlFor="mailLegal"
          >
            Texte "MENTIONS LEGALES" du mail de réponse :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Ce texte correspond au paragraphe "MENTIONS LEGALES" présent dans le
            mail automatique
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            Pour créer un nouveau paragraphe, faîtes un retour à la ligne
            (touche ENTER)
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={mailLegal}
            onChange={(e) => setMailLegal(e.target.value)}
            rows={10}
          />
          <button className="admin-button" onClick={changeMailLegal}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau texte
          </button>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Ajouter une option</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "0.9rem",
              }}
            >
              <label htmlFor="newPrestaName">Nom : </label>
              <input
                type="text"
                name="newPrestaName"
                value={newPrestaName}
                onChange={(e) => setNewPrestaName(e.target.value)}
              />
              <br />

              <label htmlFor="newPrestPosition">Position : </label>
              <input
                type="text"
                name="newPrestaPosition"
                value={newPosition}
                onChange={(e) => setNewPosition(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption1">Nom option 1 (PETIT) : </label>
              <input
                type="text"
                name="newPrestaOption1"
                value={newPrestaOption1}
                onChange={(e) => setNewPrestaOption1(e.target.value)}
              />

              <label htmlFor="newPrestaPrice1">Prix option 1 (€) : </label>
              <input
                type="number"
                name="newPrestaPrice1"
                value={newPrestaPrice1}
                onChange={(e) => setNewPrestaPrice1(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption2">Nom option 2 (MOYEN): </label>
              <input
                type="text"
                name="newPrestaOption2"
                value={newPrestaOption2}
                onChange={(e) => setNewPrestaOption2(e.target.value)}
              />

              <label htmlFor="newPrestaPrice2">Prix option 2 (€) : </label>
              <input
                type="number"
                name="newPrestaPrice2"
                value={newPrestaPrice2}
                onChange={(e) => setNewPrestaPrice2(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption3">Nom option 3 (GRAND): </label>
              <input
                type="text"
                name="newPrestaOption3"
                value={newPrestaOption3}
                onChange={(e) => setNewPrestaOption3(e.target.value)}
              />

              <label htmlFor="newPrestaPrice3">Prix option 3 (€) : </label>
              <input
                type="number"
                name="newPrestaPrice3"
                value={newPrestaPrice3}
                onChange={(e) => setNewPrestaPrice3(e.target.value)}
              />
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <label
                  style={{ marginRight: "0.5rem", color: "#00A99A" }}
                  htmlFor="projectType0"
                >
                  Conversion S/4HANA
                </label>
                <input
                  className="form-input"
                  onChange={() => handleProjectType(0)}
                  type="radio"
                  value={projectType}
                  name="projectType0"
                  checked={projectType === 0 ? true : false}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label
                  style={{ marginRight: "0.5rem", color: "#232C4B" }}
                  htmlFor="projectType1"
                >
                  Conversion S/4HANA et migration Cloud
                </label>
                <input
                  className="form-input"
                  onChange={() => handleProjectType(1)}
                  type="radio"
                  value={projectType}
                  name="projectType1"
                  checked={projectType === 1 ? true : false}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{ backgroundColor: "gray" }}
                className="admin-button"
                onClick={handleClose}
              >
                Fermer
              </button>
              <button className="admin-button" onClick={addPresta}>
                Ajouter
              </button>
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    </>
  );
};

export default AzureAdmin;
