import { useState, useEffect } from "react";
import axios from "../../axios";
import { IoIosAddCircle } from "react-icons/io";
import { FiSave } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";

const GeneralAdmin = () => {
  const [commercialMail, setCommercialMail] = useState("");
  const [introText, setIntroText] = useState("");
  const [finalOptions, setFinalOptions] = useState(null);

  const [protectDataMessage, setProtectDataMessage] = useState("");
  const [mailHypothesis, setMailHypothesis] = useState("");
  const [mailLegal, setMailLegal] = useState("");
  const [hebergeurMessage, setHebergeurMessage] = useState("");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  //PRESTATION
  const [newPrestaName, setNewPrestaName] = useState("");
  const [newPrestaOption1, setNewPrestaOption1] = useState("");
  const [newPrestaPrice1, setNewPrestaPrice1] = useState(null);
  const [newPrestaOption2, setNewPrestaOption2] = useState("");
  const [newPrestaPrice2, setNewPrestaPrice2] = useState(null);
  const [newPrestaOption3, setNewPrestaOption3] = useState("");
  const [newPrestaPrice3, setNewPrestaPrice3] = useState(null);
  const [newPosition, setNewPosition] = useState(0);

  //QUESTION
  //PRESTATION
  const [newQuestName, setNewQuestName] = useState("");
  const [newQuestOption1, setNewQuestOption1] = useState("");
  const [newQuestOption2, setNewQuestOption2] = useState("");
  const [newQuestOption3, setNewQuestOption3] = useState("");
  const [newQuestPosition, setNewQuestPosition] = useState(0);

  const [loading, setLoading] = useState(false);

  //X & Y System
  const [valX, setValX] = useState(0);
  const [valY, setValY] = useState(0);

  //Questions
  const [finalQuestions, setFinalQuestions] = useState([]);
  const [finalQuestionsLabels, setFinalQuestionsLabels] = useState([]);
  const [finalQuestionsNames, setFinalQuestionsNames] = useState([]);

  //Hebergeurs
  const [hebergeurs, setHebergeurs] = useState([]);
  const [hebergeursArray, setHebergeursArray] = useState([]);
  const [newHName, setNewHName] = useState("");
  const [newS1, setNewS1] = useState("");
  const [newS2, setNewS2] = useState("");
  const [newS3, setNewS3] = useState("");

  const [chosenHebergeurs1, setChosenHebergeurs1] = useState([]);
  const [chosenHebergeursText1, setChosenHebergeursText1] = useState([]);

  const [chosenHebergeurs2, setChosenHebergeurs2] = useState([]);
  const [chosenHebergeursText2, setChosenHebergeursText2] = useState([]);

  const [chosenHebergeurs3, setChosenHebergeurs3] = useState([]);
  const [chosenHebergeursText3, setChosenHebergeursText3] = useState([]);

  const [basePrice, setBasePrice] = useState(0);

  //OPTIONS
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  //QUESTIONS
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e) => {
    e.preventDefault();
    setShow2(true);
  };

  //HEBRGEURS
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (e) => {
    e.preventDefault();
    setShow3(true);
  };

  //CONNECT TO THA ADMIN PAGE
  const getInitData = async (e) => {
    await axios
      .post("general/data")
      .then((response) => {
        setIntroText(response.data.infos.introMessage);
        setCommercialMail(response.data.infos.commercialMail);
        setFinalOptions(
          response.data.prestations.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
        setProtectDataMessage(response.data.infos.protectDataMessage);
        setMailHypothesis(response.data.infos.mailHypothesis);
        setHebergeurMessage(response.data.infos.hebergeurMessage);
        setMailLegal(response.data.infos.mailLegal);
        setValX(response.data.infos.valX);
        setValY(response.data.infos.valY);
        setBasePrice(response.data.infos.basePrice);
        setFinalQuestions(
          response.data.questions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
      })
      .catch((error) => {});
  };

  function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }

  //ADD NEW OPTION
  const addPresta = async (e) => {
    e.preventDefault();
    if (
      !newPrestaName ||
      !newPrestaOption1 ||
      !newPrestaPrice1 ||
      !newPrestaOption2 ||
      !newPrestaPrice2 ||
      !newPrestaOption3 ||
      !newPrestaPrice3 ||
      !newPosition
    ) {
      alert("Un des champs n'est pas rempli");
      return;
    }
    const positionsArray = finalOptions.map((option) =>
      parseInt(option.position)
    );

    if (positionsArray.includes(parseInt(newPosition))) {
      alert("Cette position a déjà été choisie");
      return;
    }
    await axios
      .post("/general/prestation", {
        newPrestaName,
        newPrestaOption1,
        newPrestaPrice1,
        newPrestaOption2,
        newPrestaPrice2,
        newPrestaOption3,
        newPrestaPrice3,
        newPosition,
      })
      .then((response) => {
        setShow(false);
        setNewPrestaName("");
        setNewPrestaOption1("");
        setNewPrestaPrice1(null);
        setNewPrestaOption2("");
        setNewPrestaPrice2(null);
        setNewPrestaOption3("");
        setNewPrestaPrice3(null);
        setFinalOptions(
          response.data.finalOptions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
        alert(`Nouvelle option ${newPrestaName} ajoutée !`);
      })
      .catch((error) => {
        console.log("Error adding presta");
      });
  };

  const addQuestion = async (e) => {
    e.preventDefault();
    if (
      !newQuestName ||
      !newQuestOption1 ||
      !newQuestOption2 ||
      !newQuestOption3 ||
      !newQuestPosition
    ) {
      alert("Un des champs n'est pas rempli");
      return;
    }
    const positionsArray = finalQuestions.map((question) =>
      parseInt(question.position)
    );

    if (positionsArray.includes(parseInt(newQuestPosition))) {
      alert("Cette position a déjà été choisie");
      return;
    }

    const newh1 = chosenHebergeursText1.toString().replaceAll(",", ";");
    const newh2 = chosenHebergeursText2.toString().replaceAll(",", ";");
    const newh3 = chosenHebergeursText3.toString().replaceAll(",", ";");

    setLoading(true);

    await axios
      .post("/general/questions", {
        newQuestName,
        newQuestOption1,
        newQuestOption2,
        newQuestOption3,
        newQuestPosition,
        newh1,
        newh2,
        newh3,
      })
      .then((response) => {
        setShow2(false);
        setNewQuestName("");
        setNewQuestOption1("");
        setNewQuestOption2("");
        setNewQuestOption3("");
        setFinalQuestions(
          response.data.questions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
        setLoading(false);

        alert(`Nouvelle question ${newQuestName} ajoutée !`);
      })
      .catch((error) => {
        console.log("Error adding question");
      });
  };

  //DELETE OPTION
  const deletePresta = async (e, id) => {
    e.preventDefault();
    let optionObj = {};
    finalOptions.forEach((finalOption) => {
      if (finalOption.id === id) {
        optionObj = finalOption;
      }
    });

    const optionName = optionObj.optionName;
    var answer = window.confirm(
      `Voulez-vous vraiment supprimer l'option ${optionName} ?`
    );
    if (answer) {
      await axios
        .delete("/general/prestation", {
          data: {
            id,
          },
        })
        .then((response) => {
          alert(`Option ${optionName} supprimée !`);
          setFinalOptions(
            response.data.finalOptions.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
        })
        .catch((error) => {
          console.log("Error");
        });
    } else {
    }
  };

  const deleteQuestion = async (e, id) => {
    e.preventDefault();
    let optionObj = {};
    finalQuestions.forEach((finalQuestion) => {
      if (finalQuestion.id === id) {
        optionObj = finalQuestion;
      }
    });

    const optionName = optionObj.question;
    var answer = window.confirm(
      `Voulez-vous vraiment supprimer l'option ${optionName} ?`
    );
    if (answer) {
      setLoading(true);
      await axios
        .delete("/general/questions", {
          data: {
            id,
          },
        })
        .then((response) => {
          alert(`Option ${optionName} supprimée !`);
          setFinalQuestions(
            response.data.questions.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error");
        });
    } else {
    }
  };

  //UPDATE OPTION
  const updatePresta = async (e, id) => {
    e.preventDefault();

    let optionObj = {};
    finalOptions.forEach((finalOption) => {
      if (finalOption.id === id) {
        optionObj = finalOption;
      }
    });

    if (!optionObj) {
      console.error("Erreur option obj");
      return;
    }

    const positionsArray = finalOptions.map((option) =>
      parseInt(option.position)
    );

    if (hasDuplicates(positionsArray)) {
      alert("Cette position a déjà été choisie");
      return;
    }

    const optionName = optionObj.optionName;
    const name1 = optionObj.options[0].name;
    const price1 = optionObj.options[0].price;
    const name2 = optionObj.options[1].name;
    const price2 = optionObj.options[1].price;
    const name3 = optionObj.options[2].name;
    const price3 = optionObj.options[2].price;
    const position = optionObj.position;

    await axios
      .put("/general/prestation", {
        id,
        newPrestaName: optionName,
        newPrestaOption1: name1,
        newPrestaPrice1: price1,
        newPrestaOption2: name2,
        newPrestaPrice2: price2,
        newPrestaOption3: name3,
        newPrestaPrice3: price3,
        newPosition: position,
      })
      .then((response) => {
        alert(`Option ${optionName} modifiée`);
        setFinalOptions(
          response.data.finalOptions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
      })
      .catch((error) => console.error(error));
  };

  const updateQuestion = async (e, id) => {
    e.preventDefault();

    let optionObj = {};
    finalQuestions.forEach((finalQuestion) => {
      if (finalQuestion.id === id) {
        optionObj = finalQuestion;
      }
    });

    if (!optionObj) {
      console.error("Erreur option obj");
      return;
    }

    const positionsArray = finalQuestions.map((question) =>
      parseInt(question.position)
    );

    if (hasDuplicates(positionsArray)) {
      alert("Cette position a déjà été choisie");
      return;
    }

    const optionName = optionObj.question;
    const name1 = optionObj.choices[0].name;
    const name2 = optionObj.choices[1].name;
    const name3 = optionObj.choices[2].name;
    const position = optionObj.position;

    const newH1 = optionObj.choices[0].h;
    const newH2 = optionObj.choices[1].h;
    const newH3 = optionObj.choices[2].h;

    setLoading(true);

    await axios
      .put("/general/questions", {
        id,
        newQuestName: optionName,
        newQuestOption1: name1,
        newQuestOption2: name2,
        newQuestOption3: name3,
        newQuestPosition: position,
        newH1,
        newH2,
        newH3,
      })
      .then((response) => {
        alert(`Question ${optionName} modifiée`);
        setFinalQuestions(
          response.data.questions.sort((a, b) =>
            a.position > b.position ? 1 : -1
          )
        );
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  //CHANGE PRICE
  const changePrestaPrice = (price, optionName, i, j) => {
    const finalOptionsThree = finalOptions[i]?.options;
    const newFinalOptionsThree = [];
    const test = parseFloat(price);
    finalOptionsThree?.forEach((finalOptionOne, k) => {
      if (k === j) {
        const name = finalOptionOne.name;
        const obj = {
          name,
          price: test,
        };
        newFinalOptionsThree.push(obj);
      } else {
        newFinalOptionsThree.push(finalOptionOne);
      }
    });
    const newFinalOptions = [...finalOptions];
    newFinalOptions[i] = {
      ...newFinalOptions[i],
      options: newFinalOptionsThree,
    };
    setFinalOptions(newFinalOptions);
  };

  //CHANGE CHOICE NAME
  const changePrestaName = (name, optionName, i, j) => {
    const finalOptionsThree = finalOptions[i]?.options;
    const newFinalOptionsThree = [];
    finalOptionsThree?.forEach((finalOptionOne, k) => {
      if (k === j) {
        const price = finalOptionOne.price;
        const obj = {
          name,
          price,
        };
        newFinalOptionsThree.push(obj);
      } else {
        newFinalOptionsThree.push(finalOptionOne);
      }
    });
    const newFinalOptions = [...finalOptions];
    newFinalOptions[i] = {
      ...newFinalOptions[i],
      options: newFinalOptionsThree,
    };
    setFinalOptions(newFinalOptions);
  };

  const changeQuestionName = (name, optionName, i, j) => {
    const finalOptionsThree = finalQuestions[i]?.choices;
    const newFinalOptionsThree = [];
    finalOptionsThree?.forEach((finalOptionOne, k) => {
      if (k === j) {
        const obj = {
          ...finalOptionOne,
          name,
        };
        newFinalOptionsThree.push(obj);
      } else {
        newFinalOptionsThree.push(finalOptionOne);
      }
    });
    const newFinalOptions = [...finalQuestions];
    newFinalOptions[i] = {
      ...newFinalOptions[i],
      choices: newFinalOptionsThree,
    };
    setFinalQuestions(newFinalOptions);
  };

  //CHANGE OPTION NAME
  const changeOptionName = (newName, i) => {
    const newFinalOptions = [...finalOptions];
    newFinalOptions[i].optionName = newName;
    setFinalOptions(newFinalOptions);
  };

  const changeQuestionLabel = (newLabel, idx) => {
    const newFinalQuestions = [...finalQuestions];
    newFinalQuestions[idx].question = newLabel;
    setFinalQuestionsLabels(newFinalQuestions);
  };

  //CHANGE POSITION
  const changeOptionPosition = (newPos, i) => {
    const newFinalOptions = [...finalOptions];

    newFinalOptions[i].position = newPos;
    setFinalOptions(newFinalOptions);
  };

  const changeQuestionPosition = (newPos, i) => {
    const newFinalOptions = [...finalQuestions];

    newFinalOptions[i].position = newPos;
    setFinalQuestions(newFinalOptions);
  };

  //CHANGE COMM MAIL
  const changeCommercialMail = async (e) => {
    e.preventDefault();
    const commercialMails = commercialMail.trim().split(";");
    let correctMail = true;
    commercialMails.forEach((mail) => {
      if (
        !mail.match(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
      ) {
        correctMail = false;
      }
    });
    if (!commercialMail) {
      alert("Veuillez saisir un email");
      return false;
    } else if (!correctMail) {
      alert("Veuillez renseigner une adresse mail correcte.");
      return false;
    }
    await axios
      .put("/general/commercialMail", {
        newCommercialMail: commercialMail,
      })
      .then((response) => {
        alert(`Mail commercial changé en ${commercialMail}`);
        setCommercialMail(response.data.newCommercialMail);
      })
      .catch((error) => console.error(error));
  };

  //CHANGE INTRO MESS
  const changeIntroMessage = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!introText) {
      answer = window.confirm(
        "Attention, votre champ intitulé de la prestation est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || introText) {
      await axios
        .put("/general/introMessage", {
          newIntroMessage: introText,
        })
        .then((response) => {
          alert("Intitulé de la prestation changé !");
          setIntroText(response.data.newIntroMessage);
        })
        .catch((err) => console.error(err));
    }
  };

  //CHANGE PROTECT DATA MESS
  const changeProtectDataMessage = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!protectDataMessage) {
      answer = window.confirm(
        "Attention, votre champ intitulé protection des données personnelles est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || protectDataMessage) {
      await axios
        .put("/general/protectDataMessage", {
          newProtectDataMessage: protectDataMessage,
        })
        .then((response) => {
          alert("Intitulé protection des données changé !");
          setProtectDataMessage(response.data.newProtectDataMessage);
        })
        .catch((err) => console.error(err));
    }
  };

  //CHANGE HYPOTHESIS MAIL
  const changeMailHypothesis = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!mailHypothesis) {
      answer = window.confirm(
        "Attention, votre texte pour 'HYPOTHESES ET EXCLUSIONS' est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || mailHypothesis) {
      await axios
        .put("/general/mailHypothesis", {
          newMailHypothesis: mailHypothesis,
        })
        .then((response) => {
          alert("Texte hypothèses et exclusions changé !");
          setMailHypothesis(response.data.newMailHypothesis);
        })
        .catch((err) => console.error(err));
    }
  };

  //CHANGE LEGAL MAIL
  const changeMailLegal = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!mailLegal) {
      answer = window.confirm(
        "Attention, votre texte pour 'MENTIONS LEGALES' est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || mailLegal) {
      await axios
        .put("/general/mailLegal", {
          newMailLegal: mailLegal,
        })
        .then((response) => {
          alert("Texte mentions legales changé !");
          setMailLegal(response.data.newMailLegal);
        })
        .catch((err) => console.error(err));
    }
  };

  //CHANGE HEBERGEUR MESAGE
  const changeHebergeurMessage = async (e) => {
    e.preventDefault();
    let answer = true;
    if (!mailLegal) {
      answer = window.confirm(
        "Attention, votre texte pour 'PRASE DE PRESENTATION HEBERGEUR' est vide, voulez-vous sauvegarder quand-même ?"
      );
      if (!answer) {
        return;
      }
    }
    if (answer || mailLegal) {
      await axios
        .put("/general/hebergeurMessage", {
          newHebergeurMessage: hebergeurMessage,
        })
        .then((response) => {
          alert("Phrase de présentation hebergeur changée !");
          setHebergeurMessage(response.data.hebergeurMessage);
        })
        .catch((err) => console.error(err));
    }
  };

  //CHANGE SYSTEM XY
  const changeSystemXY = async (e) => {
    e.preventDefault();
    await axios
      .put("/general/systemxy", {
        valX,
        valY,
      })
      .then((response) => {
        alert("Système Y changé !");
        setValX(response.data.valX);
        setValY(response.data.valY);
      })
      .catch((err) => console.error(err));
  };

  //  Change base price in general
  const changePrices = async (e) => {
    e.preventDefault();

    await axios
      .put("/general/prices", {
        basePrice,
      })
      .then((response) => {
        alert("Les prix ont été changés !");
        setBasePrice(response.data.basePrice);
      })
      .catch((err) => console.error(err));
  };

  //  Get hebergeurs from database
  const getHebergeurs = async () => {
    await axios
      .post("/general/hebergeurs", {
        test: 0,
      })
      .then((response) => {
        setHebergeurs(response.data.hebergeurs);

        const newHebergeursArray = [];
        for (const hObject of response.data.hebergeurs) {
          newHebergeursArray.push(hObject.name);
        }

        setHebergeursArray(newHebergeursArray);
      })
      .catch((err) => console.error(err));
  };

  const addHebergeur = async (e) => {
    e.preventDefault();
    if (!newHName || !newS1 || !newS2 || !newS3) {
      alert("Un des champs n'est pas rempli");
      return;
    }

    await axios
      .post("/general/hebergeursAdd", {
        newHName,
        newS1,
        newS2,
        newS3,
      })
      .then((response) => {
        setShow3(false);
        setNewHName("");
        setNewS1("");
        setNewS2("");
        setNewS3("");

        setHebergeurs(response.data.hebergeurs);

        const newHebergeursArray = [];
        for (const hObject of response.data.hebergeurs) {
          newHebergeursArray.push(hObject.name);
        }

        setHebergeursArray(newHebergeursArray);
        alert(`Nouvel ${newHName} ajouté !`);
      })
      .catch((error) => {
        console.log("Error adding question");
      });
  };

  //  Change hebergeur question checkboxes
  const changeQuestionHebergeur = (e, questionIdx, choiceIdx) => {
    const checkedValue = e.target.value;

    const changeIdx = finalQuestions.findIndex(
      (question) => question.id === questionIdx
    );

    const choices = finalQuestions[changeIdx].choices;
    const oldH = choices[choiceIdx].h;

    let splittedOldH = oldH.split(";");

    if (splittedOldH.includes(checkedValue)) {
      const checkedIdx = splittedOldH.findIndex(
        (splitted) => splitted === checkedValue
      );
      splittedOldH.splice(checkedIdx, 1);
    } else {
      if (oldH === "") {
        splittedOldH = [checkedValue];
      } else {
        splittedOldH.push(checkedValue);
      }
    }
    const finalH = splittedOldH.join(";");
    const newQuestions = [...finalQuestions];
    console.log(finalH);
    newQuestions[changeIdx].choices[choiceIdx] = {
      ...newQuestions[changeIdx].choices[choiceIdx],
      h: finalH,
    };
    setFinalQuestions(newQuestions);
  };

  //  Change herbergeur system by providing id, AWS1, AWS2, AWS3
  const changeSystemHebergeur = (repIndex, id, e) => {
    const newValue = e.target.value;
    const newHebergeurs = [...hebergeurs];

    const changeIndex = newHebergeurs.findIndex(
      (hebergeur) => hebergeur.id === id
    );

    const systemIndex = `s${repIndex}`;

    newHebergeurs[changeIndex] = {
      ...newHebergeurs[changeIndex],
      [systemIndex]: newValue,
    };
    setHebergeurs(newHebergeurs);
  };

  //  Update herbergeur system by providing id --> Enregistrer orange dans le tableau
  const updateSystemHebergeur = async (e, id) => {
    e.preventDefault();

    const changeIndex = hebergeurs.findIndex(
      (hebergeur) => hebergeur.id === id
    );

    const rep1 = hebergeurs[changeIndex].s1;
    const rep2 = hebergeurs[changeIndex].s2;
    const rep3 = hebergeurs[changeIndex].s3;

    await axios
      .put("/general/systemHebergeur", {
        rep1,
        rep2,
        rep3,
        id,
      })
      .then((response) => {
        setHebergeurs(response.data.hebergeurs);

        const newHebergeursArray = [];
        for (const hObject of response.data.hebergeurs) {
          newHebergeursArray.push(hObject.name);
        }

        setHebergeursArray(newHebergeursArray);
        alert(`Configuration changée!`);
      })
      .catch((error) => {
        console.log("Error updating hebergeur");
      });
  };

  //  Delete hebergeur by providing id
  const deleteSystemHebergeur = async (e, id) => {
    e.preventDefault();

    await axios
      .delete("/general/systemHebergeur", {
        data: {
          id,
        },
      })
      .then((response) => {
        setHebergeurs(response.data.hebergeurs);
        const newHebergeursArray = [];
        for (const hObject of response.data.hebergeurs) {
          newHebergeursArray.push(hObject.name);
        }
        setHebergeursArray(newHebergeursArray);
        alert(`Hebergeur supprimée!`);
      })
      .catch((error) => {
        console.log("Error deleting hebergeur");
      });
  };

  const changeChosenHebergeur1 = (index) => {
    const newChosenHebergeurs1 = [...chosenHebergeurs1];
    const newChosenHebergeursText1 = [];
    newChosenHebergeurs1[index] = !newChosenHebergeurs1[index];
    for (let i = 0; i < hebergeurs.length; i++) {
      if (newChosenHebergeurs1[i]) {
        newChosenHebergeursText1.push(hebergeurs[i].name);
      }
    }
    setChosenHebergeursText1(newChosenHebergeursText1);
    setChosenHebergeurs1(newChosenHebergeurs1);
  };

  const changeChosenHebergeur2 = (index) => {
    const newChosenHebergeurs2 = [...chosenHebergeurs2];
    const newChosenHebergeursText2 = [];
    newChosenHebergeurs2[index] = !newChosenHebergeurs2[index];
    for (let i = 0; i < hebergeurs.length; i++) {
      if (newChosenHebergeurs2[i]) {
        newChosenHebergeursText2.push(hebergeurs[i].name);
      }
    }
    setChosenHebergeursText2(newChosenHebergeursText2);
    setChosenHebergeurs2(newChosenHebergeurs2);
  };

  const changeChosenHebergeur3 = (index) => {
    const newChosenHebergeurs3 = [...chosenHebergeurs3];
    const newChosenHebergeursText3 = [];
    newChosenHebergeurs3[index] = !newChosenHebergeurs3[index];
    for (let i = 0; i < hebergeurs.length; i++) {
      if (newChosenHebergeurs3[i]) {
        newChosenHebergeursText3.push(hebergeurs[i].name);
      }
    }
    setChosenHebergeursText3(newChosenHebergeursText3);
    setChosenHebergeurs3(newChosenHebergeurs3);
  };

  useEffect(() => {
    if (finalOptions) {
      const filtered = finalOptions.filter((option) => option.position <= 900); //Remove 900+ positions for cloud
      const positionsArray = filtered.map((x) => x.position); //Get only positions
      setNewPosition(Math.max(...positionsArray) + 1);
    }
  }, [finalOptions]);

  useEffect(() => {
    getInitData();
    getHebergeurs();
  }, []);

  useEffect(() => {
    if (finalQuestions) {
      const filtered = finalQuestions.filter(
        (option) => option.position <= 900
      ); //Remove 900+ positions for cloud
      const positionsArray = filtered.map((x) => x.position); //Get only positions
      setNewQuestPosition(Math.max(...positionsArray) + 1);
    }
  }, [finalQuestions]);

  useEffect(() => {
    if (hebergeurs && hebergeurs.length > 0) {
      if (chosenHebergeurs1 !== [] && chosenHebergeursText1 !== []) {
        const newChosenHebergeurs = Array.from(hebergeurs).map(
          (hebergeur) => true
        );
        setChosenHebergeurs1(newChosenHebergeurs);

        const newChosenHebergeursText1 = [];
        for (let i = 0; i < hebergeurs.length; i++) {
          if (newChosenHebergeurs[i]) {
            newChosenHebergeursText1.push(hebergeurs[i].name);
          }
        }
        setChosenHebergeursText1(newChosenHebergeursText1);
      }

      if (chosenHebergeurs2 !== [] && chosenHebergeursText2 !== []) {
        const newChosenHebergeurs2 = Array.from(hebergeurs).map(
          (hebergeur) => true
        );
        setChosenHebergeurs2(newChosenHebergeurs2);

        const newChosenHebergeursText2 = [];
        for (let i = 0; i < hebergeurs.length; i++) {
          if (newChosenHebergeurs2[i]) {
            newChosenHebergeursText2.push(hebergeurs[i].name);
          }
        }
        setChosenHebergeursText2(newChosenHebergeursText2);
      }

      if (chosenHebergeurs3 !== [] && chosenHebergeursText3 !== []) {
        const newChosenHebergeurs3 = Array.from(hebergeurs).map(
          (hebergeur) => true
        );
        setChosenHebergeurs3(newChosenHebergeurs3);

        const newChosenHebergeursText3 = [];
        for (let i = 0; i < hebergeurs.length; i++) {
          if (newChosenHebergeurs3[i]) {
            newChosenHebergeursText3.push(hebergeurs[i].name);
          }
        }
        setChosenHebergeursText3(newChosenHebergeursText3);
      }
    }
  }, [hebergeurs]);

  useEffect(() => {}, [finalQuestions]);

  return (
    <>
      <div className="admin-div">
        <h1
          style={{
            fontWeight: "bold",
            color: "#f28024",
            border: "3px solid #f28024",
            padding: "0.5rem 2rem",
            textAlign: "center",
            margin: "2rem",
            fontSize: "3rem",
          }}
        >
          FORMULAIRE GENERAL
        </h1>
        <form className="admin-form" />
        <form className="admin-form">
          <label
            style={{ margin: "1rem 0", fontWeight: "bold", fontSize: "1.1rem" }}
            htmlFor="commercialMail"
          >
            Mail commercial :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Si vous souhaitez envoyer la prestation à plusieurs mails, mettez un
            ";" entre chaque adresse
          </span>
          <span style={{ fontStyle: "italic", fontSize: "0.7rem" }}>
            Exemple: mail1@mail.com;mail2@mail.com
          </span>
          <br />
          <input
            style={{ padding: "0.3rem" }}
            type="text"
            name="commercialMail"
            value={commercialMail}
            onChange={(e) => setCommercialMail(e.target.value)}
          />
          <button className="admin-button" onClick={changeCommercialMail}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau mail
          </button>

          <label
            style={{ margin: "1rem 0", fontWeight: "bold", fontSize: "1.1rem" }}
            htmlFor="introText"
          >
            Intitulé prestation :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Cet intitulé correspond au texte d'introduction situé au tout début
            de la simulation.
          </span>
          <br />

          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={introText}
            onChange={(e) => setIntroText(e.target.value)}
            rows={5}
          />

          <button className="admin-button" onClick={changeIntroMessage}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau message
          </button>

          <label style={{ fontSize: "1.1rem" }} htmlFor="basePrice">
            Montant de base{" "}
            <span style={{ fontWeight: "bold" }}>Go2S/4HANA (€) :</span>
            <br />
          </label>
          <br />
          <input
            type="number"
            pattern="..."
            step="any"
            name="basePrice"
            value={basePrice}
            onChange={(e) => setBasePrice(e.target.value)}
          />
          <br />
          <button className="admin-button" onClick={changePrices}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer les prix
          </button>

          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />
          <h1 style={{ color: "#f28024" }}>Questionnaire Conversion S/4HANA</h1>
          <span
            style={{ margin: "1rem 0", fontWeight: "bold", fontSize: "1.1rem" }}
          >
            Options :{" "}
          </span>
          <table style={{ border: "1px solid black" }}>
            <tbody>
              <tr style={{ border: "1px solid black" }}>
                <th style={{ padding: "1rem" }}>Nom</th>
                <th>Position (entre 0 & 900)</th>
                <th>Petit</th>
                <th>Moyen</th>
                <th>Grand</th>
              </tr>
              {finalOptions?.map((finalOption, i) => {
                return (
                  <tr style={{ border: "1px solid gray" }} key={i}>
                    <td style={{ padding: "0.5rem" }}>
                      <textarea
                        style={{ fontSize: "0.8rem", resize: "none" }}
                        rows={4}
                        value={finalOption.optionName}
                        onChange={(e) => changeOptionName(e.target.value, i)}
                      />
                    </td>
                    <td style={{ padding: "0.5rem" }}>
                      <input
                        style={{ fontSize: "0.8rem", resize: "none" }}
                        rows={2}
                        value={finalOption.position}
                        onChange={(e) =>
                          changeOptionPosition(e.target.value, i)
                        }
                      />
                    </td>
                    {finalOption?.options.map((option, j) => (
                      <td key={`${j}${option}`}>
                        <textarea
                          style={{ fontSize: "0.65rem", fontWeight: "bold" }}
                          rows={2}
                          value={option.name}
                          onChange={(e) =>
                            changePrestaName(
                              e.target.value,
                              finalOption.optionName,
                              i,
                              j
                            )
                          }
                        />
                        <input
                          style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                          type="number"
                          pattern="..."
                          step="any"
                          value={`${option.price}`}
                          onChange={(e) =>
                            changePrestaPrice(
                              e.target.value,
                              finalOption.optionName,
                              i,
                              j
                            )
                          }
                        />
                        <span> €</span>
                      </td>
                    ))}

                    <td style={{ display: "flex" }}>
                      <button
                        style={{ fontSize: "0.7rem" }}
                        className="admin-deleteButton"
                        onClick={(e) => deletePresta(e, finalOption.id)}
                      >
                        <MdDelete style={{ marginRight: "0.3rem" }} />
                        Supprimer
                      </button>
                      <button
                        style={{ fontSize: "0.7rem" }}
                        className="admin-button"
                        onClick={(e) => updatePresta(e, finalOption.id)}
                      >
                        <FiSave style={{ marginRight: "0.3rem" }} />
                        Enregistrer
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="admin-button" onClick={handleShow}>
            <IoIosAddCircle style={{ marginRight: "0.3rem" }} />
            Ajouter une option
          </button>
          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />
          {/** SYSTEM X & Y */}
          <h3>Choix du X et Y du système : </h3>
          <label
            style={{
              margin: "0.5rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
            htmlFor="valX"
          >
            Valeur de X :
          </label>
          <br />
          <input
            style={{ padding: "0.3rem" }}
            type="text"
            name="valX"
            value={valX}
            onChange={(e) => setValX(e.target.value)}
          />

          <label
            style={{
              margin: "0.5rem 0",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
            htmlFor="valY"
          >
            Valeur de Y :
          </label>
          <br />
          <input
            style={{ padding: "0.3rem" }}
            type="text"
            name="valY"
            value={valY}
            onChange={(e) => setValY(e.target.value)}
          />
          <button className="admin-button" onClick={changeSystemXY}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau système X/Y
          </button>

          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />

          <h1 style={{ color: "#f28024" }}>
            Définition de l’architecture cloud
          </h1>

          <br />

          <span>
            Pour modifier des questions, veuillez enregistrer les modifications
            question par question{" "}
          </span>

          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />

          {!loading && (
            <table style={{ border: "1px solid black" }}>
              <tbody>
                <tr style={{ border: "1px solid black" }}>
                  <th style={{ padding: "1rem" }}>Nom</th>
                  <th>Position (entre 0 & 900)</th>
                  <th>Choix 1</th>
                  <th>Choix 2</th>
                  <th>Choix 3</th>
                </tr>
                {finalQuestions?.map((finalOption, i) => {
                  return (
                    <tr style={{ border: "1px solid gray" }} key={i}>
                      <td style={{ padding: "0.5rem" }}>
                        <textarea
                          style={{ fontSize: "0.8rem", resize: "none" }}
                          rows={4}
                          value={finalOption.question}
                          onChange={(e) =>
                            changeQuestionLabel(e.target.value, i)
                          }
                        />
                      </td>
                      <td style={{ padding: "0.5rem" }}>
                        <input
                          style={{
                            fontSize: "0.8rem",
                            resize: "none",
                            maxWidth: "50px",
                          }}
                          rows={2}
                          value={finalOption.position}
                          onChange={(e) =>
                            changeQuestionPosition(e.target.value, i)
                          }
                        />
                      </td>
                      {finalOption?.choices.map((option, j) => (
                        <>
                          <td key={`${j}${option}`}>
                            <textarea
                              style={{
                                fontSize: "0.65rem",
                                fontWeight: "bold",
                              }}
                              rows={2}
                              value={option.name}
                              onChange={(e) =>
                                changeQuestionName(
                                  e.target.value,
                                  finalOption.question,
                                  i,
                                  j
                                )
                              }
                            />
                            {hebergeursArray.length > 0 &&
                              hebergeursArray.map((hebergeur, idx) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                    }}
                                    key={idx}
                                  >
                                    <label>{hebergeur}</label>
                                    <input
                                      type="checkbox"
                                      defaultChecked={option.h.includes(
                                        hebergeur
                                      )}
                                      value={hebergeur}
                                      onChange={(e) =>
                                        changeQuestionHebergeur(
                                          e,
                                          finalOption.id,
                                          j
                                        )
                                      }
                                    />
                                    <br />
                                  </div>
                                );
                              })}
                          </td>
                        </>
                      ))}

                      <td style={{ display: "flex" }}>
                        <button
                          style={{ fontSize: "0.7rem" }}
                          className="admin-deleteButton"
                          onClick={(e) => deleteQuestion(e, finalOption.id)}
                        >
                          <MdDelete style={{ marginRight: "0.3rem" }} />
                          Supprimer
                        </button>
                        <button
                          style={{ fontSize: "0.7rem" }}
                          className="admin-button"
                          onClick={(e) => updateQuestion(e, finalOption.id)}
                        >
                          <FiSave style={{ marginRight: "0.3rem" }} />
                          Enregistrer
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <button className="admin-button" onClick={handleShow2}>
            <IoIosAddCircle style={{ marginRight: "0.3rem" }} />
            Ajouter une question
          </button>

          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />

          {/** SYSTEM HEBERGEURS */}
          <h3>Configuration des réponses : </h3>

          {hebergeurs &&
            hebergeurs.length > 0 &&
            hebergeurs.map((hebergeur) => {
              return (
                <>
                  <span
                    style={{
                      margin: "0.5rem 0",
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    {hebergeur.name} :
                  </span>
                  <label htmlFor={hebergeur.s1}>Valeur réponse 1 :</label>
                  <input
                    style={{ padding: "0.3rem" }}
                    type="text"
                    name={hebergeur.s1}
                    value={hebergeur.s1}
                    onChange={(e) => changeSystemHebergeur(1, hebergeur.id, e)}
                  />

                  <label htmlFor={hebergeur.s2}>Valeur réponse 2 :</label>
                  <input
                    style={{ padding: "0.3rem" }}
                    type="text"
                    name={hebergeur.s2}
                    value={hebergeur.s2}
                    onChange={(e) => changeSystemHebergeur(2, hebergeur.id, e)}
                  />

                  <label htmlFor={hebergeur.s3}>Valeur réponse 3 :</label>
                  <input
                    style={{ padding: "0.3rem" }}
                    type="text"
                    name={hebergeur.s3}
                    value={hebergeur.s3}
                    onChange={(e) => changeSystemHebergeur(3, hebergeur.id, e)}
                  />
                  <br />
                  <div style={{ display: "flex" }}>
                    <button
                      className="admin-button-save"
                      onClick={(e) => updateSystemHebergeur(e, hebergeur.id)}
                    >
                      <FiSave style={{ marginRight: "0.3rem" }} />
                      Enregistrer
                    </button>
                    <button
                      className="admin-deleteButton"
                      onClick={(e) => deleteSystemHebergeur(e, hebergeur.id)}
                    >
                      <FiSave style={{ marginRight: "0.3rem" }} />
                      Supprimer
                    </button>
                  </div>
                </>
              );
            })}

          <button className="admin-button" onClick={handleShow3}>
            <IoIosAddCircle style={{ marginRight: "0.3rem" }} />
            Ajouter un nouvel hébergeur
          </button>

          <hr
            style={{ width: "30%", alignSelf: "center", margin: "0.7rem 0" }}
          />

          <label
            style={{ margin: "1rem 0", fontWeight: "bold", fontSize: "1.1rem" }}
            htmlFor="hebergeurMessage"
          >
            Phrase de présentation hebergeurs
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Cette phrase correspond à la phrase de présentation des hébergeurs
            qui sera envoyée dans le mail
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={hebergeurMessage}
            onChange={(e) => setHebergeurMessage(e.target.value)}
            rows={5}
          />
          <button className="admin-button" onClick={changeHebergeurMessage}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouvelle phrase
          </button>

          <label
            style={{ margin: "1rem 0", fontWeight: "bold", fontSize: "1.1rem" }}
            htmlFor="dataProtectMessage"
          >
            Intitulé protection des données personnelles :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Cet intitulé correspond au texte présent dans le champ de texte de
            protection des données personnelles.
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={protectDataMessage}
            onChange={(e) => setProtectDataMessage(e.target.value)}
            rows={15}
          />
          <button className="admin-button" onClick={changeProtectDataMessage}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouvel intitulé
          </button>

          <label
            style={{ margin: "1rem 0", fontWeight: "bold", fontSize: "1.1rem" }}
            htmlFor="mailHypothesis"
          >
            Texte "HYPOTHESES ET EXCLUSIONS" du mail de réponse :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Ce texte correspond au paragraphe "HYPOTHESES ET EXCLUSIONS" présent
            dans le mail automatique
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            Pour créer une liste à puces, veuillez insérer un "-" avant
            l'élement
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={mailHypothesis}
            onChange={(e) => setMailHypothesis(e.target.value)}
            rows={20}
          />
          <button className="admin-button" onClick={changeMailHypothesis}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau texte
          </button>

          <label
            style={{ margin: "1rem 0", fontWeight: "bold", fontSize: "1.1rem" }}
            htmlFor="mailLegal"
          >
            Texte "MENTIONS LEGALES" du mail de réponse :
          </label>
          <span style={{ fontSize: "0.7rem" }}>
            Ce texte correspond au paragraphe "MENTIONS LEGALES" présent dans le
            mail automatique
          </span>
          <span style={{ fontSize: "0.7rem" }}>
            Pour créer un nouveau paragraphe, faîtes un retour à la ligne
            (touche ENTER)
          </span>
          <br />
          <textarea
            style={{ fontSize: "0.8rem", padding: "0.3rem" }}
            value={mailLegal}
            onChange={(e) => setMailLegal(e.target.value)}
            rows={10}
          />
          <button className="admin-button" onClick={changeMailLegal}>
            <FiSave style={{ marginRight: "0.3rem" }} />
            Enregistrer nouveau texte
          </button>

          {/** ADD OPTION */}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Ajouter une option</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "0.9rem",
              }}
            >
              <label htmlFor="newPrestaName">Nom : </label>
              <input
                type="text"
                name="newPrestaName"
                value={newPrestaName}
                onChange={(e) => setNewPrestaName(e.target.value)}
              />
              <br />

              <label htmlFor="newPrestPosition">Position : </label>
              <input
                type="text"
                name="newPrestaPosition"
                value={newPosition}
                onChange={(e) => setNewPosition(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption1">Nom option 1 (PETIT) : </label>
              <input
                type="text"
                name="newPrestaOption1"
                value={newPrestaOption1}
                onChange={(e) => setNewPrestaOption1(e.target.value)}
              />

              <label htmlFor="newPrestaPrice1">Prix option 1 (€) : </label>
              <input
                type="number"
                name="newPrestaPrice1"
                value={newPrestaPrice1}
                onChange={(e) => setNewPrestaPrice1(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption2">Nom option 2 (MOYEN): </label>
              <input
                type="text"
                name="newPrestaOption2"
                value={newPrestaOption2}
                onChange={(e) => setNewPrestaOption2(e.target.value)}
              />

              <label htmlFor="newPrestaPrice2">Prix option 2 (€) : </label>
              <input
                type="number"
                name="newPrestaPrice2"
                value={newPrestaPrice2}
                onChange={(e) => setNewPrestaPrice2(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption3">Nom option 3 (GRAND): </label>
              <input
                type="text"
                name="newPrestaOption3"
                value={newPrestaOption3}
                onChange={(e) => setNewPrestaOption3(e.target.value)}
              />

              <label htmlFor="newPrestaPrice3">Prix option 3 (€) : </label>
              <input
                type="number"
                name="newPrestaPrice3"
                value={newPrestaPrice3}
                onChange={(e) => setNewPrestaPrice3(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{ backgroundColor: "gray" }}
                className="admin-button"
                onClick={handleClose}
              >
                Fermer
              </button>
              <button className="admin-button" onClick={addPresta}>
                Ajouter
              </button>
            </Modal.Footer>
          </Modal>

          {/** ADD QUESTION */}
          <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Ajouter une question</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "0.9rem",
              }}
            >
              <label htmlFor="newPrestaName">Nom : </label>
              <input
                type="text"
                name="newPrestaName"
                value={newQuestName}
                onChange={(e) => setNewQuestName(e.target.value)}
              />
              <br />

              <label htmlFor="newPrestPosition">Position : </label>
              <input
                type="text"
                name="newPrestaPosition"
                value={newQuestPosition}
                onChange={(e) => setNewQuestPosition(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption1">Nom option 1 (PETIT) : </label>
              <input
                type="text"
                name="newPrestaOption1"
                value={newQuestOption1}
                onChange={(e) => setNewQuestOption1(e.target.value)}
              />
              <br />
              <label>Hébergeurs compatibles :</label>
              <div style={{ display: "flex" }}>
                {hebergeursArray &&
                  hebergeursArray.length > 0 &&
                  hebergeursArray.map((hebergeur, index) => {
                    return (
                      <div
                        style={{ display: "flex", marginRight: "0.3rem" }}
                        key={index}
                      >
                        <label>{hebergeur}</label>
                        <input
                          type="checkbox"
                          checked={chosenHebergeurs1[index]}
                          onChange={() => changeChosenHebergeur1(index)}
                        />
                      </div>
                    );
                  })}
              </div>
              <br />

              <label htmlFor="newPrestaOption2">Nom option 2 (MOYEN): </label>
              <input
                type="text"
                name="newPrestaOption2"
                value={newQuestOption2}
                onChange={(e) => setNewQuestOption2(e.target.value)}
              />
              <br />
              <label>Hébergeurs compatibles :</label>
              <div style={{ display: "flex" }}>
                {hebergeursArray &&
                  hebergeursArray.length > 0 &&
                  hebergeursArray.map((hebergeur, index) => {
                    return (
                      <div
                        style={{ display: "flex", marginRight: "0.3rem" }}
                        key={index}
                      >
                        <label>{hebergeur}</label>
                        <input
                          type="checkbox"
                          checked={chosenHebergeurs2[index]}
                          onChange={() => changeChosenHebergeur2(index)}
                        />
                      </div>
                    );
                  })}
              </div>
              <br />

              <label htmlFor="newPrestaOption3">Nom option 3 (GRAND): </label>
              <input
                type="text"
                name="newPrestaOption3"
                value={newQuestOption3}
                onChange={(e) => setNewQuestOption3(e.target.value)}
              />
              <br />
              <label>Hébergeurs compatibles :</label>
              <div style={{ display: "flex" }}>
                {hebergeursArray &&
                  hebergeursArray.length > 0 &&
                  hebergeursArray.map((hebergeur, index) => {
                    return (
                      <div
                        style={{ display: "flex", marginRight: "0.3rem" }}
                        key={index}
                      >
                        <label>{hebergeur}</label>
                        <input
                          type="checkbox"
                          checked={chosenHebergeurs3[index]}
                          onChange={() => changeChosenHebergeur3(index)}
                        />
                      </div>
                    );
                  })}
              </div>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{ backgroundColor: "gray" }}
                className="admin-button"
                onClick={handleClose2}
              >
                Fermer
              </button>
              <button className="admin-button" onClick={addQuestion}>
                Ajouter
              </button>
            </Modal.Footer>
          </Modal>

          {/** ADD HEBERGEUR */}
          <Modal
            show={show3}
            onHide={handleClose3}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Ajouter un hébergeur</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "0.9rem",
              }}
            >
              <label htmlFor="newPrestaName">Nom : </label>
              <input
                type="text"
                name="newHName"
                value={newHName}
                onChange={(e) => setNewHName(e.target.value)}
              />
              <br />
              <label htmlFor="newPrestaOption1">Nom option 1 (PETIT) : </label>
              <input
                type="text"
                name="newS1"
                value={newS1}
                onChange={(e) => setNewS1(e.target.value)}
              />

              <label htmlFor="newPrestaOption2">Nom option 2 (MOYEN): </label>
              <input
                type="text"
                name="newS2"
                value={newS2}
                onChange={(e) => setNewS2(e.target.value)}
              />

              <label htmlFor="newPrestaOption3">Nom option 3 (GRAND): </label>
              <input
                type="text"
                name="newS3"
                value={newS3}
                onChange={(e) => setNewS3(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{ backgroundColor: "gray" }}
                className="admin-button"
                onClick={handleClose3}
              >
                Fermer
              </button>
              <button className="admin-button" onClick={addHebergeur}>
                Ajouter
              </button>
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    </>
  );
};

export default GeneralAdmin;
