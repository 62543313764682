import axios from "axios";

const instance = axios.create({
  baseURL: "https://go2s4.fr/",
});

export default instance;

//http://localhost:8081
//https://go2s4.fr/
