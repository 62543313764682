import React from "react";
import { Row, Col } from "react-bootstrap";

import "../Formline.css";

const AWSFormLine = ({
  optionName,
  options,
  handleOption,
  value,
  valueIdx,
}) => {
  return (
    <Row style={{ margin: "0.8rem 0", display: "flex", alignItems: "center" }}>
      <Col
        lg={3}
        sm={12}
        style={{
          display: "flex",
          justifyContent: window.innerWidth > 576 ? "flex-end" : "center",
          margin: window.innerWidth > 576 ? "0rem" : "0",
        }}
      >
        <span
          className="formLine-name"
          style={{ fontWeight: "bold", fontSize: "1.05rem" }}
        >
          {optionName}
        </span>
      </Col>
      {options.map((option, i) => (
        <Col
          lg={3}
          sm={4}
          style={{ display: "flex", alignItems: "center" }}
          key={`${option.name}${i}`}
        >
          <input
            style={{ margin: window.innerWidth > 576 ? "0 0.5rem" : "0.2rem" }}
            type="radio"
            name={optionName}
            checked={option.name === value}
            value={option.name}
            onChange={(e) =>
              handleOption(valueIdx, option.name, optionName, i + 1)
            }
          />
          <label className="formLine-label" htmlFor={optionName}>
            {option.name}
          </label>
        </Col>
      ))}
    </Row>
  );
};

export default AWSFormLine;
