import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import axios from "../../axios";
import { FaUserAlt, FaLock } from "react-icons/fa";

import "../Admin.css";
import AWSAdmin from "../AWS/AWSAdmin";
import Header from "./Header";
import GeneralAdmin from "../General/GeneralAdmin";
import AzureAdmin from "./AzureAdmin";

const Admin = () => {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogged, setIsLogged] = useState(false);

  //CONNECT TO THA ADMIN PAGE
  const connectAdmin = async (e) => {
    e.preventDefault();
    await axios
      .post("/login", {
        mail,
        password,
      })
      .then((response) => {
        if (response.data.connected) {
          setMail("");
          setPassword("");
          setIsLogged(true);
        }
      })
      .catch((error) => {
        alert("Mauvais identifiants");
      });
  };

  if (!isLogged) {
    return (
      <>
        <Header />
        <div className="admin-div">
          <form className="admin-form">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FaUserAlt style={{ marginRight: "0.3rem" }} />

              <label htmlFor="mail">Identifiant :</label>
              <input
                style={{ marginLeft: "0.4rem", width: "40%" }}
                type="text"
                name="mail"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
            </div>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FaLock style={{ marginRight: "0.3rem" }} />
              <label htmlFor="password">Mot de passe :</label>
              <input
                style={{ marginLeft: "0.4rem", width: "40%" }}
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button
              className="admin-button"
              type="submit"
              onClick={connectAdmin}
            >
              Connexion
            </button>
          </form>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="admin-div">
        <Tabs
          defaultActiveKey="general"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="general" title="Général">
            <GeneralAdmin />
          </Tab>
          <Tab eventKey="aws" title="AWS">
            <AWSAdmin />
          </Tab>
          <Tab eventKey="azure" title="Azure">
            <AzureAdmin />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Admin;
